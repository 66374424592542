let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

// https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component

export function currency(value) {
    value = parseFloat(value);

    if (isNaN(value)) {
        return 0;
    }

    return formatter.format(value);
}
