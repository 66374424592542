<template>
  <form method="POST" @submit.prevent="submit" enctype="multipart/form-data">
    <div class="card mt-2 p-2">
      <div class="row align-items-center">
        <div class="col-6">
          <h3 class="card-title ml-2">Category Management</h3>
        </div>
        <div class="col-6 text-right">
          <a :href="route('admin.catalog.index')" class="btn btn-secondary mr-1">Back</a>
          <input type="submit" class="btn btn-primary" value="Update" v-if="categories.length !== 0">
        </div>
      </div>
    </div>
    <div class="card p-3" id="category-card" v-if="categories.length === 0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h3>Category not found.</h3>
            <h4>You don't have any category. Please create some categories in square.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="card p-3" v-else>
      <h6 class="mb-2">Unselect any Square categories that you do not want to display in your Catalog.</h6>
      <div v-for="category in categories" class="row mt-2">
        <div class="col-12 ml-2">
          <div class="form-check mb-1">
            <input
              :id="category.id"
              :value="category.id"
              class="form-check-input"
              name="category"
              type="checkbox"
              v-model="checkedCategories"
            >
            <label class="form-check-label mt-1">{{category.category}}</label>
          </div>
        </div>

      </div>

    </div>
  </form>

</template>

<script>

import Form from 'laravel-form-validation';

export default {
  name: 'CategoryIndex',
  data() {
    return {
      checkedCategories: [],
      form: new Form(),
      isAllZero : false,

    };
  },
  props: {
    authUser: {
      type: Object,
    },
    categories: {
      type: Array,
    },
  },
  created() {
    this.categories.forEach((category) => {
      if(category.is_active === 1) {
        this.checkedCategories.push(category.id);
      }
    })
  },
  methods: {
    submit(){
      if(this.checkedCategories.length == 0){
        this.$toast.error('You must have to select one category')
      }
      else{
        this.form.put(route('admin.category.update', this.authUser), {
          "checkedCategories" : this.checkedCategories
        }).then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            window.location.href = route('admin.catalog.index');
            this.$toast.success("Category updated successfully");
          }
        }).catch((error) => {
          console.log(error);
          this.$toast.error('Something went wrong')
        })
      }
    },
  },

};
</script>

