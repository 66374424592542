<template>
  <div class="modal modal-center fade kitchen-category-edit-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display :none; '"
       id="categoryEditModal"
       ref="categoryEditModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content w-100 h-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Edit Category</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0 mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="editCategory()">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter the category"
                      class="form-control"
                      :class="{ 'is-invalid' : form.$errors.has('name')}"
                      v-model="category.name"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('name')"
                    >
                      {{ form.$errors.first('name') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Image</label>
                    <div class="mb-3 image-wrapper" v-if="imagePreview">
                      <img :src="imagePreview" alt="">
                    </div>
                    <div class="mb-3 image-wrapper" v-else>
                      <img :src="initial.image" alt="">
                    </div>
                    <label class="text-break" v-if="image !== undefined && !imagePreview">{{ image.substring(image.lastIndexOf('/')+1) }}</label>
                    <input
                      type="file"
                      class="form-control mt-1"
                      :class="{ 'is-invalid' : form.$errors.has('image')}"
                      ref="file"
                      @change="onFileChange"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('image')"
                    >
                      {{ form.$errors.first('image') }}
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="button" class="btn btn-secondary" @click="closeModal" value="Cancel">
                    <input type="submit" class="btn btn-primary ml-3" value="Update">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Form from "laravel-form-validation";
import {utcToZoned} from "@/js/Components/Shared/datatime";

export default {
  name: "KitchenCategoryEditModal.vue",

  props: {
    showModal: {
      type: Boolean,
    },
    authUser: {
      type: Object,
    },
    initial: {
      type: Object,
    },
  },

  data() {
    return {
      category: {

      },
      image: this.initial.image,
      imagePreview: '',
      form: new Form(),

    }
  },
  methods: {
    closeModal() {
      this.form = new Form();
      this.$emit('closeKitchenCategoryEditModal');
      this.category.name = this.image = this.imagePreview = '';
      this.$refs.file.type = 'text';
      this.$refs.file.type = 'file';
    },
    editCategory(){
      this.data = {
        name : this.category.name ,
        image: this.image ,
        admin_id : this.authUser.id
      };
      (typeof this.image === "string") ? this.data.image = '' : '';
      this.form.put(route('admin.kitchen.category.update' , this.initial.id ) ,this.data )
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            this.$toast.success("Category updated successfully");
            this.$emit('closeKitchenCategoryEditModal',response,this.initial._index);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.procesing = false
        })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
        this.$refs.file.type = 'text';
        this.$refs.file.type = 'file';
      } else {
        this.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    }
  },
  watch: {
    initial(newValue) {
      this.category.name = newValue.name;
      this.image = newValue.image;
    }
  }

}
</script>

<style scoped>

  .image-wrapper {
    max-width: 60px;
    max-height: 60px;
  }
  .image-wrapper img {
    width: 100%;
    object-fit: contain;
  }
</style>
