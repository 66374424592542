<template>
  <div class="card mt-2">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title mb-1 ml-2">History</h2>
      <h2 v-if="histories" class="card-title mb-1 ml-2">Recipe : {{ recipe ? recipe.name : '' }}</h2>
      <div class="ml-auto">
        <button class="btn btn-secondary" @click="goBack">Back</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row align-items-start mb-3">
        <div class="col-lg-8">
<!--          <ul class="nav nav-pills">-->
<!--            <li class="nav-item">-->
<!--              <a :class="{'active' : causer_type === 'admin'}" class="btn btn-sm btn-outline-info mr-1"-->
<!--                 @click="changeCauserType(true)">Admin</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a :class="{'active' : causer_type === 'employee'}" class="btn btn-sm btn-outline-info"-->
<!--                 @click="changeCauserType(false)">Employee</a>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-12">
          <table v-if="histories" id="table" class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th class="text-primary">Action</th>
                <th class="text-primary">Description</th>
                <th class="text-primary">Updated By</th>
                <th class="text-primary">Updated at</th>
                <th class="text-primary">
                  <a href="#">Actions</a>
                </th>
              </tr>
            </thead>
            <tbody v-if="histories.length > 0">
              <tr v-for="(history, index) in histories" :key="index">
                <td>
                  {{ history.action }}
                </td>
                <td>
                  {{ history.description }}
                </td>
                <td>
                  {{ history.causer.name }}
                </td>
                <td>
                  {{ formatDateTime(history.created_at) }}
                </td>
                <td>
                  <a data-toggle="dropdown" id="ellipsis-icon" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-h"/>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
                    <span class="dropdown-item sync-button" @click="viewHistoryChanges(history)">
                      View Changes
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <td colspan="8">
                <div class="text-center">
                  <strong>No Results Found</strong>
                </div>
              </td>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import dayjs from 'dayjs';

export default {
  name: 'HistoryIndex',
  props: {
    recipe: {
      type: Object
    },
    isAdmin: {
      type: Boolean,
    }
  },
  created() {
     this.fetchHistory();
  },
  data() {
    return {
      causer_type: this.isAdmin ? 'admin' : 'employee',
      processing: false,
      response: {},
    }
  },
  methods: {
    changeCauserType(isAdmin) {
      this.causer_type = isAdmin ? 'admin' : 'employee';
      this.fetchHistory();
    },
    fetchHistory() {
      this.processing = true;
      this.$http.get(route('admin.kitchen.history.index'), {
        params: {historyable_type: 'recipe', historyable_id: this.recipe.id,
        causer_type: this.causer_type},
      })
          .then((response) => {
            this.response = response;
          })
          .catch((error) => {
            this.$toast.error('Something went wrong');
          })
          .finally(() =>{
            this.processing = false;
          })
    },
    viewHistoryChanges(history) {
      this.$emit('view-history-changes', history, (this.causer_type === 'admin' ));
    },
    goBack() {
      this.$emit('view-recipe-history');
    },
    formatDateTime(dateTime) {
      return dayjs(dateTime).format('YYYY-MM-DD')
    },
  },
  computed: {
    histories: function () {
      return this.response.data;
    },
  },
}
</script>
