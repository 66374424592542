import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

export const serverFormat = 'YYYY-MM-DD HH:mm:ss';

export function zonedToUtc(datetime, tz, format = serverFormat) {
    return dayjs.tz(datetime, tz).tz('utc').utc().format(format)
}

export function utcToZoned(datetime, tz, format = serverFormat) {
    return dayjs.utc(datetime).tz(tz).format(format);
}

export function formatDatetime(datetime, format = serverFormat) {
    return dayjs(datetime).format(format);
}
