<template>
  <div class="modal
  . fade kitchen-category-add-modal modal-appear"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'display: block;' : 'display: none'"
       id="stepDetailModal"
       ref="stepDetailModal"
       tabindex="-1"
       aria-labelledby="stepDetailModal"
       aria-hidden="true">
    <div class="modal-dialog modal-md h-100 my-0">
      <div class="modal-content w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Do you want to change the uploaded image with the catalog image ?</h5>
        </div>
        <div class="modal-body p-0">
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="changeImage(true)">Yes</button>
            <button type="submit" class="btn btn-danger" @click="changeImage(false)">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipeCatalogImageModal',
  props: {
    showModal: {
      type: Boolean,
    },
  },
  methods: {
    changeImage(value) {
      this.$emit('changeImage', value)
    },
  }
}
</script>

<style scoped>
.modal {
  overflow: hidden;
}
.modal .modal-content {
  overflow-y: auto;
}
</style>
