import 'jquery';
import 'popper.js'
import 'bootstrap';

import Vue from 'vue';
import {ModalPlugin} from "bootstrap-vue/esm/components/modal";
import {TabsPlugin} from "bootstrap-vue/esm/components/tabs";
import {DropdownPlugin} from "bootstrap-vue/esm/components/dropdown";
import {PopoverPlugin} from "bootstrap-vue/esm/components/popover";
import {FormFilePlugin} from 'bootstrap-vue/esm/components/form-file';

Vue.use(ModalPlugin);
Vue.use(TabsPlugin);
Vue.use(DropdownPlugin);
Vue.use(PopoverPlugin);
Vue.use(FormFilePlugin);

import {currency} from "@/js/currency";
Vue.filter('currency', currency);

// Application configs
Vue.prototype.$appConfig = window.appConfig || {};

// axios configs
import axios from 'axios';

window.axios = axios;
window.axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import CatalogueImageCard from "@/js/Components/Catalogue/CatalogueImageCard.vue";
Vue.component(CatalogueImageCard.name, CatalogueImageCard);

// Lets not use axios directly
Vue.prototype.$http = axios;

import VueToast from 'vue-toast-notification';

Vue.use(VueToast, {
   duration: 5000,
   type: 'default'
});

// Importing Ziggy route helper from composer's vendor folder
import Ziggy from 'ziggy-js';
// route() helper is available everywhere
window.route = Ziggy;
// Exposing route() helper method to vue templates
Vue.mixin({
    methods: {
        route: Ziggy
    }
});

import 'toastr/build/toastr.min';

jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (!options.crossDomain) {
        jqXHR.setRequestHeader('X-CSRF-Token', window.appConfig.csrfToken);
    }
    return jqXHR;
});

jQuery.ajaxSetup({
    cache: false,
    beforeSend: function (xhr) {
        xhr.setRequestHeader('Accept', 'application/json');
    }
});

import {VueFormPlugin} from "laravel-form-validation";
Vue.use(VueFormPlugin)

import '../sass/vendor.scss';
import '../sass/app.scss';
import '../sass/subscription.scss';
