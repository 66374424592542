<template>
    <b-tab class="bg-white shadow-sm" @click.once="fetch">
        <template #title>
            <i class="fas fa-file-invoice-dollar"></i> Invoices
        </template>
        <section class="table-responsive mb-3">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Invoice</th>
                    <th>Amount (in $)</th>
                    <th>Status</th>
                    <th>Paid at</th>
                    <th>Period</th>
                </tr>
                </thead>
                <tbody v-if="invoices.length">
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td>
                            <a :href="invoice.invoice_pdf" target="_blank">
                                <span class="fas fa-file-pdf"/> {{ invoice.id }}
                            </a>
                        </td>
                        <td>{{ centsToDollar(invoice.amount_paid) | currency }}</td>
                        <td>{{ invoice.status }}</td>
                        <td>{{ formatDateTime(invoice.status_transitions.paid_at) }}</td>
                        <td>{{ invoicePeriod(invoice.period_start, invoice.period_end) }}</td>
                    </tr>
                </tbody>
                <tfoot v-else>
                    <tr>
                        <td colspan="5" class="text-center">
                            <span class="fas fa-spinner fa-spin fa-3x"></span>
                            <h4 class="mt-2">Loading...</h4>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section>
    </b-tab>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration);

export default {
    name: "InvoiceTab",
    data() {
        return {
            processing: false,
            response: {},
            admin: this.initial
        }
    },
    methods: {
        fetch() {
            this.processing = true;
            this.$http.get(route('admin.account.invoices'))
                .then(({data: response}) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        invoicePeriod (periodStart, periodEnd) {
            let startDate = new Date(periodStart*1000);
            let endDate =  new Date(periodEnd*1000);

            return `${dayjs(startDate).format('YYYY-MMM-DD')} to
            ${dayjs(endDate).format('YYYY-MMM-DD')}`;
        },
        centsToDollar(amount) {
            return amount/100;
        },
        formatDateTime (dateTime) {
            return dayjs(dateTime*1000).format('YYYY-MMM-DD HH:MM:ss a')
        },
    },
    computed: {
        invoices() {
            return this.response || [];
        }
    }
}
</script>
