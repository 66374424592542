<template>
  <div class="modal modal-center fade kitchen-category-add-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display: none'"
       id="categoryAddModal"
       ref="categoryAddModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content w-100 h-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Add Category</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>


        </div>
        <div class="modal-body p-0 mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="addCategory()">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter the category"
                      class="form-control"
                      :class="{ 'is-invalid' : form.$errors.has('name')}"
                      v-model="category"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('name')"
                    >
                      {{ form.$errors.first('name') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Image</label>
                    <div class="mb-2 image-wrapper" v-if="imagePreview">
                      <img :src="imagePreview" alt="">
                    </div>
                    <input
                      type="file"
                      ref="inputFile"
                      class="form-control my-1"
                      :class="{ 'is-invalid' : form.$errors.has('image')}"
                      @change="onFileChange"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('image')"
                    >
                      {{ form.$errors.first('image') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="button" class="btn btn-secondary" @click="closeModal" value="Cancel">
                    <input type="submit" class="btn btn-primary ml-3" value="Save">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>
import Form from "laravel-form-validation";

export default {
  name: "KitchenCategoryAddModal.vue",
  props: {
    showModal: {
      type: Boolean,
    },
    authUser: {
      type: Object,
    }
  },
  data() {
    return {
      category: '',
      image: '',
      imagePreview: '',
      form: new Form()

    }
  },
  created() {
    this.category = this.image = ''

  },

  methods: {
    closeModal() {
      this.form = new Form();
      this.$emit('closeKitchenCategoryAddModal');
      this.category = this.image = this.imagePreview = null
      this.$refs.inputFile.type = 'text';
      this.$refs.inputFile.type = 'file';
    },
    addCategory(){
      this.procesing = true;
      this.form.post(route('admin.kitchen.category.store'), { 'name' : this.category , 'image' : this.image, 'admin_id' : this.authUser.id  })
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            this.$toast.success('Category added successfully.');
            this.$emit('closeKitchenCategoryAddModal');
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.procesing = false
        })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
        this.$refs.inputFile.type = 'text';
        this.$refs.inputFile.type = 'file';
      } else {
        this.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    }
  }
}
</script>

<style scoped>
.image-wrapper {
  max-width: 60px;
  max-height: 60px;
}
.image-wrapper img {
  object-fit: contain;
  width: 100%;
}
</style>
