<template>
    <b-tab @click="init" @click.once="fetch">
        <template #title>
            <i class="fas fa-credit-card"/> Cards
        </template>
        <section class="table-responsive mb-3">
            <form @submit.prevent="submit()" ref="cardForm">
                <div class="card mb-3 shadow-sm">
                    <div class="card-body">
                        <div class="form-row mb-2">
                            <div class="col-md-6">
                                <div class="input-group required">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                    </div>
                                    <input
                                        id="input_address_line_1"
                                        type="text"
                                        class="form-control"
                                        name="address_line_1"
                                        placeholder="Address line 1"
                                        v-model.trim="card.address_line_1"
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                    </div>
                                    <input
                                        id="input_address_line_2"
                                        type="text"
                                        class="form-control"
                                        name="address_line_2"
                                        placeholder="Address line 2"
                                        v-model.trim="card.address_line_2"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-row mb-2">
                            <div class="col-md-6">
                                <div class="input-group required">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                    </div>
                                    <input
                                        id="input_city"
                                        type="text"
                                        class="form-control"
                                        name="city"
                                        placeholder="City"
                                        v-model.trim="card.city"
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group required">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                    </div>
                                    <input
                                        id="input_zip-code"
                                        type="text"
                                        class="form-control"
                                        name="zip_code"
                                        placeholder="Zip code"
                                        v-model.trim="card.zip_code"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-row mb-2">
                            <div class="col-md-6">
                                <div class="input-group required mb-0">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-user"/>
                                        </span>
                                    </div>
                                    <input
                                        id="input_card-holder-name"
                                        name="card-holder"
                                        placeholder="Card holder name"
                                        type="text"
                                        maxlength="255"
                                        required
                                        class="form-control"
                                        v-model.trim="card.holder"
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <div ref="cardElement" class="form-control pt-2"/>
                                </div>
                            </div>
                        </div>

                        <button
                            class="btn btn-primary mb-2"
                            :disabled="processing"
                        >
                            <i class="fas fa-save"/> Save new card
                        </button>

                        <div class="text-center my-4 text-muted" v-show="processing">
                            <i class="fas fa-spinner fa-spin fa-3x mb-3"/>
                            <h6>Loading ...</h6>
                        </div>

                        <section v-if="cards.length">
                            <div class="card bg-secondary rounded-lg shadow-sm mb-2 mt-2" v-for="(card, index) in cards"
                                 :key="card.id">
                                <div class="card-body text-light">
                                    <div class="row">
                                        <div class="col-6">
                                            <i :class="getCardIconClass(card.card.brand)" class="fa-2x"/>
                                        </div>
                                        <div class="col-6 text-right">
                                            <div v-if="isDefaultCard(card)">
                                                <span
                                                    class="badge-pill bg-success shadow text-light badge-light"><small>Default</small></span>
                                            </div>
                                            <b-dropdown dropleft
                                                        variant="link"
                                                        toggle-class="btn btn-link text-secondary text-decoration-none mt-0"
                                                        no-caret
                                                        v-else>
                                                <template #button-content>
                                                    <i class="fas fa-ellipsis-v text-light"/>
                                                </template>
                                                <b-dropdown-item
                                                    href="#"
                                                    @click="setDefault(card, index)"
                                                    :disabled="card._processing"
                                                >
                                                    <i class="fas fa-check-circle text-success"/> Default
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    href="#"
                                                    @click="deleteCard(card, index)"
                                                    :disabled="card._processing"
                                                >
                                                    <i class="fas fa-trash-alt text-danger"/> Delete
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <h3 class="text-left text-monospace mb-2 mt-2">
                                                <span class="mr-3">xxxx</span>
                                                <span class="mr-3">xxxx</span>
                                                <span class="mr-3">xxxx</span>{{ card.card.last4 }}
                                            </h3>
                                        </div>
                                        <div class="col-6 text-right my-auto">
                                            Saved:
                                            <span> {{ timestampToDate(card.created) }} </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <h5 class="mb-0">
                                                {{ card.billing_details.name || 'NA' }}
                                            </h5>
                                        </div>
                                        <div class="col-6 text-right my-auto">
                                            <h6 class="mb-0"> Valid till: {{ getValidTill(card.card) }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section v-show="!processing" v-else>
                            <h6 class="text-center text-muted mt-4">
                                There is no card attached yet.<br>Add your first card to get started.
                            </h6>
                        </section>
                    </div>
                </div>
            </form>
        </section>
    </b-tab>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {CardIcons} from '@/js/Components/Shared/CardIcons';
import {scrollToTop} from "@/js/scrollToTop";
import loadScript from 'load-script2';
/*global Stripe*/

dayjs.extend(relativeTime);

export default {
    name: 'CardTab',
    data() {
        return {
            processing: false,
            card: {
                element: null,
            },
            stripe: null,
            cards: [],
            customer: {},
        }
    },
    methods: {
        init() {
            this.processing = true;
            loadScript('https://js.stripe.com/v3')
                .then(() => {
                    if (this.stripe) {
                        this.card.element.destroy();
                    }
                    this.stripe = Stripe(window.appConfig.stripeKey);

                    this.card.element = this.stripe.elements().create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#495057',
                            }
                        }
                    });
                    this.card.element.mount(this.$refs.cardElement);
                    this.processing = false;
                })
        },
        async submit() {
            this.processing = true;
            const {paymentMethod, error} = await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card.element,
                billing_details: {
                    name: this.card.holder,
                    address: {
                        line1: this.card.address_line_1,
                        line2: this.card.address_line_2,
                        city: this.card.city,
                        postal_code: this.card.zip_code,
                    }
                },
            });

            if (error) {
                this.processing = false;
                console.error(error)
                this.$toast.error(error.message);
            } else {
                this.sendToServer(paymentMethod)
            }
        },
        sendToServer(paymentMethod) {
            return this.$http.post(route('admin.account.card.store'), {
                payment_method: paymentMethod.id
            })
                .then(() => {
                    this.$toast.success('Card saved successfully!')
                    this.resetForm();
                    this.fetch()
                })
                .catch(error => {
                    console.error(error);
                    this.$toast.error('Error in saving Card.')
                })
                .finally(() => {
                    this.processing = false
                })
        },
        resetForm() {
            this.card.element.clear();
            this.card = {}
        },
        fetch() {
            this.processing = true;
            this.$http.all([
                this.$http.get(route('admin.account.stripe.customer')),
                this.$http.get(route('admin.account.card.index'), {params: {limit: 100}})
            ])
                .then(this.$http.spread(({data: customer}, {data: cards}) => {
                    this.cards = cards.data.data || [];
                    this.customer = customer.data;
                }))
                .catch(error => {
                    console.log(error)
                    this.$toast.error('Unable to fetch card list.')
                })
                .finally(() => {
                    this.processing = false;
                    scrollToTop();
                });
        },
        async deleteCard(card, index) {
            const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure to delete card ending with ${card.card.last4}?`, {
                title: 'Please confirm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
            })

            if (!confirm) {
                return;
            }

            this.$set(this.cards[index], '_processing', true);
            this.$http.delete(route('admin.account.card.delete', [card.id]))
                .then(() => {
                    this.$toast.success('Card has been deleted.')
                    this.$delete(this.cards, index)
                })
                .catch(error => {
                    console.error(error.message)
                    this.$set(this.cards[index], '_processing', false);
                })
        },
        async setDefault(card, index) {
            const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure to make the card ending with ${card.card.last4} as default card?`, {
                title: 'Please confirm',
                okVariant: 'success',
                okTitle: 'Proceed',
                cancelTitle: 'Cancel',
            })

            if (!confirm) {
                return;
            }

            this.$set(this.cards[index], '_processing', true);
            this.$http.put(route('admin.account.card.update'), {
                'payment_method': card.id,
            })
                .then(() => {
                    this.$toast.success('Card has been updated.')
                    this.fetch();
                })
                .catch(error => {
                    console.error(error)
                    this.$set(this.cards[index], '_processing', false);
                })
        },
        timestampToDate(timestamp) {
            return dayjs(dayjs(timestamp * 1000).toJSON()).fromNow();
        },
        getValidTill(card) {
            return `${card.exp_year} / ${card.exp_month < 10 ? card.exp_month.toString().padStart(2, '0') : card.exp_month}`;
        },
        getCardIconClass(brand) {
            return CardIcons[brand] || CardIcons.unknown;
        },
        isDefaultCard(card) {
            return card.id === this.defaultCard;
        }
    },
    computed: {
        defaultCard() {
            return this.customer.invoice_settings.default_payment_method.id;
        }
    }
}
</script>
