<template>
<div class="text-center subscription-card">
    <h3 class="text-bold text-secondary">Please select a subscription below to continue.</h3>
      <ul class="nav nav-pills plan-toggle d-inline-flex justify-content-center align-items-center">
        <li>
          <a href="#" class="active" data-toggle="pill" @click="showMonthlyPlans()">Monthly</a>
        </li>
        <li><a href="#" data-toggle="pill" @click="showYearlyPlans()">Yearly</a></li>
      </ul>
    <div class="row mt-4 subscription-card-wrapper justify-content-center">
        <div id="monthly-plan" class="col-lg-6" v-if="showMonthlyPlanDiv" v-for="subscription in monthlySubscriptions" :key="subscription.id">
          <div class="card" v-if="subscription.cycle === 'month'">

            <div class="card-header">
              <div class="brand">
                <span>{{ subscription.name }}</span>
              </div>
              <div class="tab-content">
                <div id="monthly-plan">
                  <h3>${{ subscription.price }}</h3>
                  <hr>
                  <h4>{{ subscription.name }}</h4>
                </div>
              </div>

            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li>Unlimited admin accounts.</li>
                <li>Unlimited user accounts.</li>
                <li v-if="subscription.name === 'KitchenHand Monthly'">Unlimited Recipes.</li>
                <li>Unlimited Square items SKUs.</li>
                <li>Multiple Square locations.</li>
              </ul>
              <hr>
              <button
                class="btn btn-outline-primary"
                :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id)"
                @click="subscribe(subscription)"
              >
                <i
                  class="fas"
                  :class="processing && !subscription.price ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
                /> Subscribe
              </button>
            </div>
          </div>
        </div>

      <div id="yearly-plan" class="col-lg-6" v-if="showYearlyPlanDiv" v-for="subscription in yearlySubscriptions" :key="subscription.id">
        <div class="card" v-if="subscription.cycle === 'year'">

          <div class="card-header">
            <div class="brand">
              <span>{{ subscription.name }}</span>
            </div>
            <div class="tab-content">
              <div id="monthly-plan">
                <h3>${{ subscription.price }}</h3>
                <hr>
                <h4>{{ subscription.name }}</h4>
              </div>
            </div>

          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>Unlimited admin accounts.</li>
              <li>Unlimited user accounts.</li>
              <li v-if="subscription.name === 'KitchenHand Yearly'">Unlimited Recipes.</li>
              <li>Unlimited Square items SKUs.</li>
              <li>Multiple Square locations.</li>
            </ul>
            <hr>
            <button
              class="btn btn-outline-primary"
              :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id)"
              @click="subscribe(subscription, disableSubscriptions)"
            >
              <i
                class="fas"
                :class="processing && !subscription.price ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
              /> Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary trial-btn"
          v-if="showTrial()"
          :disabled="trialProcessing"
          @click="startTrial()">
    Start 30 day free trial
  </button>
</div>
</template>

<script>
export default {
    name: 'SubscriptionCard',
    props: {
        initial: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        processing : {
            type: Boolean,
            required: true,
        },
        userSubscriptions: {
            type: Array,
            required:true
        },
        stripeProducts: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
          trialProcessing: false,
          showMonthlyPlanDiv: true,
          showYearlyPlanDiv: false,
          disableSubscriptions: []
        }
    },
    created() {
      this.findDisableSubscription()
    },
    methods: {
        subscribe(subscription, disableSubscriptions) {
            this.$emit('subscribe', subscription, disableSubscriptions);
        },
        findDisableSubscription() {
          for( let subscription of this.userSubscriptions) {
            for( let [key, value] of Object.entries(this.stripeProducts)) {
               if(value.includes(subscription)) {
                 this.disableSubscriptions.push(...value);
               }
            }
          }
        },
      startTrial() {
        this.trialProcessing = true;
        this.$http.post(route('admin.subscriptions.subscribe.trial'))
          .then(response => {
            this.$toast.success('Congratulations! your trial subscription has been started.');
            window.location = route('admin.');
          })
          .catch(() => {
            this.$toast.error('You cannot get trial subscription now. Please contact admin')
          })
          .finally(() => {
            this.trialProcessing = false;
          })
      },

      showMonthlyPlans() {
        this.showMonthlyPlanDiv = true
        this.showYearlyPlanDiv = false
      },

      showYearlyPlans() {
        this.showMonthlyPlanDiv = false
        this.showYearlyPlanDiv = true
      },

      showTrial() {
          return !this.disableSubscriptions.length && !this.user.trial_ends_at
      }
    },
    computed: {
        monthlySubscriptions() {
            return this.initial.filter( function (subscription){
              return subscription.cycle === 'month';
            });
        },
        yearlySubscriptions() {
          return this.initial.filter( function (subscription) {
            return subscription.cycle === 'year';
          });
        }
    }
}
</script>

<style scoped>
.plan-toggle a {
  display: block;
}
.subscription-card-wrapper > div {
  margin: 15px auto;
}
.trial-btn {
  font-size: 14px;
  display: inline-block;
  min-width: 350px;
  background: #0E54EA;
  font-weight: 700;
  border: none;
  transition: all .3s ease-in-out;
}
.trial-btn:hover {
  background: #007bff;
  border: none;
}
</style>
