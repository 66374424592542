<template>
    <div class="content-wrapper">
        <section class="content">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h3 class="card-title">Standard Label Formats List</h3>
                                </div>
                                <div class="col-6 text-right" v-if="authUser.is_super_admin">
                                    <a :href="route('admin.label-format.create')" class="btn btn-primary btn-small">Add Label Format</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                                <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2" v-model="params.per_page">
                                    <option disabled>Per page</option>
                                    <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                                </select>

                                <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search" name="search"
                                        v-model.trim="params.search">
                                <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2" :disabled="processing">
                                    <i class="fa fa-search fa-fw"/>
                                </button>
                                <div>
                                    <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing" aria-label="Loading"/>
                                </div>
                            </form>
                            <div class="table-wrapper table-responsive">
                        <table id="table" class="table table-bordered table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th>
                                        <a href="#">Label Image</a>
                                    </th>
                                    <th v-for="(column, key, index) in columns" :key="index">
                                        <a href="#" @click="sort(key)">{{ column }}</a>
                                    </th>
                                    <th>
                                        <a href="#">Action</a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="sortedFormats.length > 0">
                                <tr v-for="(format, index) in sortedFormats" :key="index">
                                    <td><img
                                            :src="format.image_url"
                                            :alt="format.name"
                                            class="rounded-circle"
                                            @click="showModal(format.image_url, format.name)"
                                            style="cursor:pointer;width:60px;height:60px"
                                        />
                                    </td>
                                    <td>{{ format.type.type }}</td>
                                    <td>{{ format.name }}</td>
                                    <td :class="format.pivot.status ? 'text-success text-bold' : 'text-danger text-bold'">{{ format.pivot.status ? "Active" : "Inactive" }}</td>
                                    <td>{{ formatDateTime(format.created_at) }}</td>
                                    <td>{{ formatDateTime(format.updated_at) }}</td>
                                    <td>
                                    <div class="btn-group btn-group-sm">
                                        <a title="Edit" :href="route('admin.label-format.edit', format.id)" class="btn btn-primary"><i class="fas fa-edit"></i></a>
                                        <a title="Delete" href="#" @click="deleteLabelFormat(format)" class="btn btn-danger" v-if="authUser.is_super_admin"><i class="fas fa-trash"></i></a>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-else>
                                <td colspan="7">
                                <div class="text-center">
                                    <strong>No Results Found</strong>
                                </div>
                                </td>
                            </tfoot>
                        </table>
                        </div>
                        <b-modal ref="image-modal" hide-footer hide-header centered>
                            <i class="fas fa-times float-right close" @click="closeModal()"></i>
                            <img
                                :src="modalImage"
                                :alt="modalImageName"
                                class="catalog-modal-class image-modal" />
                        </b-modal>
                        <div class="text-center">
                                <pagination class="mb-0"
                                            @pagination-change-page="pageNavigated"
                                            :data="response"
                                            :show-disabled="true"
                                            :limit="2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';
import {scrollToTop} from "@/js/scrollToTop.js";

export default {
  name: "LabelFormatIndex",
  components: {
      Pagination,
  },
  props: {
    initial: {
      type: Object,
    },
    authUser: {
      type: Object,
    },
  },
  data() {
    return {
      processing: false,
      sortKey: "updated_at",
      sortOrder: "desc",
      params: {
          page: 1,
          search: null,
          per_page: 15,
      },
      response: {},
      columns: {'type_id':'Label Type', 'name':'Label Format Name', 'status':'Status', 'created_at':'Created At', 'updated_at':'Updated At'},
      modalImage: "",
      modalImageName: "",
    }
  },
  created() {
      this.response = this.initial;
  },
  methods: {
    sort(sortKey) {
      if (sortKey === this.sortKey) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      }
      this.sortKey = sortKey;
    },
    showModal(imageUrl, imageName) {
        this.modalImage = imageUrl
        this.modalImageName = imageName
        this.$refs['image-modal'].show()
    },
    closeModal() {
        this.$refs['image-modal'].hide();
    },
    pageNavigated(page = 1) {
        this.params.page = page;
        this.fetch();
    },
    fetch() {
        this.processing = true;
        scrollToTop();
        this.$http.get(route('admin.label-format.index'), {
            params: this.params,
        })
            .then((response) => {
                this.response = response.data;
            })
            .catch((error) => {
                this.$toast.error('Something went wrong');
            })
            .finally(() =>{
                this.processing = false;
            })
    },
    async deleteLabelFormat(format) {
      const confirm = await this.$bvModal.msgBoxConfirm('Please be careful, Label Format will be deleted permanently.', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.$http.delete(route('admin.label-format.destroy', [format.id]))
      .then(() => {
          this.$toast.success("Label Format has been deleted")
          setTimeout(function() { location.reload() }, 1000)
      })
      .catch(error => {
        console.error(error.message)
      })

    },

    formatDateTime (dateTime) {
        return dayjs(dateTime).format('YYYY-MMM-DD HH:MM:ss a')
    },

  },

  computed: {
    formats: function () {
      return this.response.data;
    },
    sortedFormats: function () {
      return this.formats.sort((a, b) => {
        let modifier = 1;
        if (this.sortOrder === "desc") modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
