<template>
    <b-tab active class="bg-white shadow-sm">
        <template #title>
            <i class="fas fa-vote-yea"></i> Subscriptions
        </template>
        <section class="table-responsive mb-3">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Subscription</th>
                        <th>Amount (in $)</th>
                        <th>Duration</th>
                        <th>Start at</th>
                        <th>Expire on</th>
                        <th>Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="subscriptions.length">
                    <tr v-for="subscription in subscriptions" :key="subscription.id">
                        <td>{{ subscription.product.name }}</td>
                        <td>{{ subscription.product.price | currency }}</td>
                        <td>{{ durationInMonths(subscription.created_at, subscription.ends_at) }}</td>
                        <td>{{ formatDateTime(subscription.created_at) }}</td>
                        <td>{{ formatDateTime(subscription.ends_at) }}</td>
                        <td>{{ subscription.cancel_at_period_end ? 'Non-recurring' : 'Recurring' }}</td>
                        <td>
                            <button
                                class="btn btn-sm btn-primary"
                                :disabled="subscription.cancel_at_period_end === 1"
                                v-if="subscription.is_active"
                                @click="cancelSubscription(subscription)"
                            >
                                Cancel
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section class="text-center">
            <div class="table-responsive-sm">
                <pagination class="mb-0"
                            @pagination-change-page="pageNavigated"
                            :data="response"
                            :show-disabled="true"
                            :limit="2"/>
            </div>
        </section>
    </b-tab>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import {scrollToTop} from "@/js/scrollToTop.js";

dayjs.extend(duration);

export default {
    name: "SubscriptionTab",
    props: {
        initial: {
            type: Object,
            required: true,
        }
    },
    components: {
      Pagination,
    },
    data() {
        return {
            params: {
                page: 1,
                search: null,
                per_page: 15,
            },
            response: {},
            admin: this.initial
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        pageNavigated(page = 1) {
            this.params.page = page;
            this.fetch();
        },
        fetch() {
            scrollToTop();
            this.$http.get(route('admin.account.subscription'), {
                params: this.params,
            })
                .then((response) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
        },
        formatDateTime (dateTime) {
            return dayjs(dateTime).format('YYYY-MMM-DD HH:MM:ss a')
        },
        durationInMonths(startDate, endDate) {
            return this.getDuration(Math.round(dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asMonths()));
        },
        getDuration(duration) {
            return duration < 2 ? `${duration} month` : `${duration} months`;
        },
        async cancelSubscription(subscription) {
            const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to cancel
             your subscription? If you cancel your subscription, you will no longer be about to access
             your account after the end date of your current subscription.`, {
                title: 'Are you sure?',
                okVariant: 'danger',
                okTitle: 'Proceed',
                cancelTitle: 'Cancel',
            })

            if (!confirm) {
                return;
            }

            this.$http.post(route('admin.account.cancel.subscription'), subscription)
                .then(() => {
                    this.$toast.success('Your request to cancel subscription is submitted successfully.');
                    window.location.reload();
                })
                .catch(error => {
                    console.error(error);
                    this.$toast.error('Something went wrong.');
                })
        }
    },
    computed: {
        subscriptions() {
            return this.response.data || [];
        }
    }
}
</script>
