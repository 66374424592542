<template>
  <div class="modal modal-center fade move-to-worklist-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display: none'"
       id="moveToWorkListModal"
       ref="moveToWorkListModal"
       tabindex="-1"
       aria-labelledby="moveToWorkListModal"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Add to Recipe Worklist</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="container m-3">
            <div class="row">
              <div class="col-11">
                <h6>Recipe Yield : {{ recipeData.catalog.name }}</h6>
                <div class="form-group">
                  <label for="batch_size" class="form-label">Enter Batch Size</label>
                  <input class="form-control"
                         type="text"
                         v-model="recipe.batch_size"
                         :class="{ 'is-invalid' : form.$errors.has('batch_size')}"
                  >
                  <span class="text-danger">{{ form.$errors.first('batch_size') }}</span>
                </div>
                <h6>Batch Yield : {{ recipeData.quantity }}</h6>
                <h6>Total Yield : {{ recipeData.quantity*recipe.batch_size }}</h6>
                <div class="form-group">
                  <label for="" class="form-label">Select Due Date</label>
                  <input class="form-control"
                         type="date"
                         v-model="recipe.due_date"
                         :class="{ 'is-invalid' : form.$errors.has('due_date')}"
                  >
                  <span class="text-danger">{{ form.$errors.first('due_date') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="for-label">Enter Additional Notes</label>
                  <textarea class="form-control"
                            name="notes"
                            placeholder="Enter the Additional Notes"
                            v-model="recipe.notes"
                            rows="5"
                            :class="{ 'is-invalid' : form.$errors.has('notes')}"/>
                  <span class="text-danger">{{ form.$errors.first('notes') }}</span>
                </div>
                <div class="form-group">
                  <label for="" class="form-label ml-2">Open to all employees</label>
                  <label class="ml-2">
                    <input type="radio" value="YES" v-model="openToAllEmployeeRadio"> YES
                  </label>

                  <label class="ml-2">
                    <input type="radio" value="NO" v-model="openToAllEmployeeRadio"> NO
                  </label>
                </div>
                <div v-if="!recipe.open_to_all_employees" class="form-group">
                  <label for="" class="form-label">Assign to an Employee</label>
                  <select class="form-control"
                          v-model="recipe.employee_id"
                          :class="{ 'is-invalid' : form.$errors.has('employee_id')}"
                          :disabled="recipe.open_to_all_employees"
                  >
                    <option v-for="(employee, index) in employees" :key="index" :value="employee.id">{{ employee.name }}</option>
                  </select>
                  <span class="text-danger">{{ form.$errors.first('employee_id') }}</span>
                </div>
                <div class="form-group">
                  <button class="btn btn-outline-primary" @click="closeModal">Cancel</button>
                  <button class="btn btn-primary ml-3" @click="addToWorkList">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'laravel-form-validation';

export default {
  name: 'MoveToWorkListModal',
  props: {
    showModal: {
      type: Boolean,
    },
    initial: {
      type: Array,
    },
    recipeData: {
      type: Object,
    }
  },
  data() {
    return {
      employees: this.initial,
      recipe: {
        recipe_id: this.recipeData.id,
        batch_size: '',
        due_date: '',
        employee_id: '',
        open_to_all_employees: true,
        updated_at: this.recipeData.updated_at,
      },
      openToAllEmployeeRadio: 'YES',
      form: new Form,
    }
  },
  methods: {
    addToWorkList() {
      this.form.post(route('admin.kitchen.work-list.store'), this.recipe)
        .then(() => {
          this.$toast.success('Recipe Moved to worklist.')
          this.$emit('recipeMovedToWorkList')
          this.$emit('closeModal')
        })
        .catch((error) => {
          console.log(error)
        })

    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  watch: {
    recipe: {
      handler: function (newValue) {
        (newValue.open_to_all_employees === true) ? this.recipe.employee_id = null : ''
      },
      deep: true,
    },
    openToAllEmployeeRadio: {
      handler: function (newValue) {
        this.recipe.open_to_all_employees = (newValue === 'YES')
      }
    }
  }
}
</script>

<style scoped>

</style>
