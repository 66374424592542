<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <RecipeHistory v-if="recipeHistory"
                         :initial="initial"
                         :categories="categories"
                         @show-history-index="showHistoryIndex"/>
          <HistoryIndex v-if="historyIndex"
                        :recipe="recipe"
                        :is-admin="isAdmin"
                        @view-history-changes="viewHistoryChanges"
                        @view-recipe-history="viewRecipeHistory"/>
          <HistoryShow v-if="historyShow"
                       :show-modal="showHistoryModal"
                       :history="history"
                       :is-admin="isAdmin"
                       @close-history-modal="showHistoryIndex"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import RecipeHistory from '@/js/KitchenHand/Components/History/RecipeHistory';
import HistoryIndex from '@/js/KitchenHand/Components/History/HistoryIndex';
import HistoryShow from '@/js/KitchenHand/Components/History/HistoryShow';

export default {
  name: 'History',
  components: {HistoryShow, HistoryIndex, RecipeHistory},
  props: {
    initial: {
      type: Object,
    },
    categories: {
      type: Array,
    },
  },
  created() {
    this.response = this.initial;
  },
  data() {
    return {
      response: {},
      recipeHistory: true,
      historyIndex: false,
      historyShow: false,
      showHistoryModal: false,
      recipe: null,
      history: null,
      isAdmin: true
    }
  },
  methods: {
    showHistoryIndex(recipe, isAdmin) {
      this.recipe = recipe;
      this.recipeHistory = false;
      this.historyShow = false;
      this.historyIndex = true;
      this.isAdmin = isAdmin;
    },
    viewHistoryChanges(history, isAdmin) {
      this.history = history;
      this.historyIndex = true;
      this.historyShow = true;
      this.showHistoryModal= true;
      this.isAdmin= isAdmin;
    },
    viewRecipeHistory() {
      this.recipeHistory = true;
      this.historyIndex = false;
    }
  },
  computed: {
  },
}
</script>
