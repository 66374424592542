<template>
  <div class="modal
  . fade kitchen-category-add-modal modal-appear"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'display: block;' : 'display: none'"
       id="stepDetailModal"
       ref="stepDetailModal"
       tabindex="-1"
       aria-labelledby="stepDetailModal"
       aria-hidden="true">
    <div class="modal-dialog modal-md h-100 my-0">
      <div class="modal-content w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete recipes?</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div v-if="recipes.length" class="mt-3">
            <div class="ml-3">
              <h6>Following Recipes can't be deleted as they are in WorkList.</h6>
            </div>
            <ul>
              <li class="text-danger" style="font-size: 15px;" v-for="recipe in recipes">
                {{ recipe }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
            <button type="submit" class="btn btn-danger" @click="deleteRecipes">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipeDeleteModal',
  props: {
    showModal: {
      type: Boolean,
    },
    recipes: {
      type: Array,
    },
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed')
    },
    deleteRecipes() {
      this.closeModal()
      this.$emit('deleteRecipes')
    }
  }
}
</script>

<style scoped>
.modal {
  overflow: hidden;
}
.modal .modal-content {
  overflow-y: auto;
}
</style>
