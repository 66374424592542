<template>
    <div class="content-wrapper">
        <section class="content">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h3 class="card-title">Store List</h3>
                                </div>
                                <div class="col-6 text-right">
                                    <a :href="route('admin.store.create')" class="btn btn-primary btn-small">Add Store</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                                <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2" v-model="params.per_page">
                                    <option disabled>Per page</option>
                                    <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                                </select>

                                <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search" name="search"
                                        v-model.trim="params.search">
                                <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2" :disabled="processing">
                                    <i class="fa fa-search fa-fw"/>
                                </button>
                                <div>
                                    <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing" aria-label="Loading"/>
                                </div>
                            </form>
                            <div class="table-wrapper table-responsive">
                        <table id="table" class="table table-bordered table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th v-for="(column, key, index) in columns" :key="index">
                                        <a href="#" @click="sort(key)">{{ column }}</a>
                                    </th>
                                    <th>
                                        <a href="#">Action</a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="sortedStores.length > 0">
                                <tr v-for="(store, index) in sortedStores" :key="index">
                                    <td>{{ store.website }}</td>
                                    <td>{{ store.location.name }}</td>
                                    <td>{{ store.address }}</td>
                                    <td>{{ store.city }}</td>
                                    <td>{{ store.state }}</td>
                                    <td>{{ store.zipcode }}</td>
                                    <td>{{ store.contact_number }}</td>
                                    <td :class="store.status ? 'text-success text-bold' : 'text-danger text-bold'">{{ store.status ? 'Active' : 'Inactive' }}</td>
                                    <td>{{ formatDateTime(store.created_at) }}</td>
                                    <td>{{ formatDateTime(store.updated_at) }}</td>
                                    <td>
                                    <div class="btn-group btn-group-sm">
                                        <a title="Edit" :href="route('admin.store.edit', store.id)" class="btn btn-primary"><i class="fas fa-edit"></i></a>
                                        <a title="Delete" href="#" @click="deleteStore(store)" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-else>
                                <td colspan="8">
                                    <div class="text-center">
                                        <strong>No Results Found</strong>
                                    </div>
                                </td>
                            </tfoot>
                        </table>
                    </div>
                        <div class="text-center">
                                <pagination class="mb-0"
                                            @pagination-change-page="pageNavigated"
                                            :data="response"
                                            :show-disabled="true"
                                            :limit="2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';
import {scrollToTop} from "@/js/scrollToTop.js";

export default {
    name: "StoreIndex",
    components: {
        Pagination
    },
    props: {
        initial: {
            type: Object
        }
    },
    data() {
        return {
            processing: false,
            response: {},
            sortKey: 'website',
            sortOrder: 'asc',
            params: {
                page: 1,
                search: null,
                per_page: 15,
            },
            columns: {'website':'Business Name or Website', 'location_id':'Location', 'address':'Street Address', 'city':'City', 'state':'State', 'zipcode':'Zipcode', 'contact_number':'Phone', 'status':'Status', 'created_at':'Created At', 'updated_at':'Updated At'}
        }
    },
    created() {
        this.response = this.initial
    },
    methods: {
        sort(sortKey) {
            if (sortKey === this.sortKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            }
            this.sortKey = sortKey;
        },
        pageNavigated(page = 1) {
            this.params.page = page;
            this.fetch();
        },
        fetch() {
            this.processing = true;
            scrollToTop();
            this.$http.get(route('admin.store.index'), {
                params: this.params,
            })
                .then((response) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() =>{
                    this.processing = false;
                })
        },
        async deleteStore(store) {
            const confirm = await this.$bvModal.msgBoxConfirm('Please be careful, if you delete this store then related users will be deleted permanently too from admin.', {
                title: 'Are you sure?',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                bodyTextVariant: 'danger',
                bodyClass: 'h3',
                size: 'lg',
            })

            if (!confirm) {
                return;
            }

            this.$http.delete(route('admin.store.destroy', [store.id]))
            .then(() =>{
                this.$toast.success('Store has been deleted.')
                setTimeout(function(){ location.reload() }, 1000);
            })
            .catch(error => {
                console.error(error.message);
            })
        },

        formatDateTime (dateTime) {
            return dayjs(dateTime).format('YYYY-MMM-DD HH:MM:ss a')
        }
    },
    computed: {
        stores: function() {
            return this.response.data;
        },
        sortedStores: function() {
            return this.stores.sort((a, b) => {
                let modifier = 1;
                if (this.sortOrder === "desc") modifier = -1;
                if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
                if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
            });
        }
    }
}
</script>
