<template>
    <div>
        <form @submit.prevent="submit" >
            <div class="card mb-3 shadow-sm">
                <div class="card-body row no-gutters align-middle">
                    <div class="col-md-4 text-center bg-light rounded pt-5 pt-md-5 py-sm-3">
                        <h3 class="mt-5 mt-md-5 mt-sm-0">${{ subscription.price }}</h3>
                        <h5>{{ subscription.name }}</h5>
                    </div>
                    <div class="col-md-8 pl-2">
                        <div class="form-check mb-2" v-if="user.is_stripe_customer">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="pay-using-default-card"
                                v-model="isDefaultPaymentMethod"
                                :value="isDefaultPaymentMethod"
                                @click="switchPaymentMethod">
                            <label
                                class="form-check-label mt-1"
                                for="pay-using-default-card"
                            >
                                <strong>Pay through default payment method</strong>
                            </label>
                        </div>
                        <section :class="{'d-none' : ! isDefaultPaymentMethod }">
                            <h5>Default payment method</h5>
                            <credit-card
                                v-if="isDefaultPaymentMethod"
                                :payment-method="defaultPaymentMethod"
                            ></credit-card>
                        </section>
                        <section :class="{'d-none' : isDefaultPaymentMethod }">
                            <h5>Card billing address details</h5>
                            <div class="form-row mb-2">
                                <div class="col-md-6">
                                    <div class="input-group required mb-sm-2">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                        </div>
                                        <input
                                            id="input_address_line_1"
                                            name="address_line_1"
                                            placeholder="Address line 1"
                                            type="text"
                                            maxlength="255"
                                            :required="!isDefaultPaymentMethod"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.line1')}"
                                            v-model.trim="card.address.line1"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.line1')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.line1') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                        </div>
                                        <input
                                            id="input_address_line_2"
                                            name="address_line_2"
                                            placeholder="Address line 2"
                                            type="text"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.line2')}"
                                            v-model.trim="card.address.line2"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.line2')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.line2') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mb-2">
                                <div class="col-md-6">
                                    <div class="input-group required mb-sm-2">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                        </div>
                                        <input
                                            id="input_city"
                                            name="city"
                                            placeholder="City"
                                            type="text"
                                            maxlength="50"
                                            :required="!isDefaultPaymentMethod"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.city')}"
                                            v-model.trim="card.address.city"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.city')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.city') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group required">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="fas fa-map-marked-alt"/>
                                            </span>
                                        </div>
                                        <input
                                            id="input_zip-code"
                                            name="zip_code"
                                            placeholder="Zip code"
                                            type="text"
                                            maxlength="10"
                                            :required="!isDefaultPaymentMethod"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.postal_code')}"
                                            v-model="card.address.postal_code"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.postal_code')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.postal_code') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5>Card details</h5>
                            <div class="form-row mb-3">
                                <div class="col-md-6">
                                    <div class="input-group required mb-0 mb-sm-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="fas fa-user"/>
                                             </span>
                                        </div>
                                        <input
                                            id="input_card-holder-name"
                                            name="card-holder"
                                            placeholder="Card holder name"
                                            type="text"
                                            maxlength="255"
                                            :required="!isDefaultPaymentMethod"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.name')}"
                                            v-model.trim="card.holder"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.name')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.name') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-0">
                                        <div ref="cardElement" class="form-control pt-2"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div class="text-right">
                            <button
                                class="btn btn-dark"
                                :disabled="processing"
                                @click="cancelPayment"
                            >
                                <i class="fa fa-times-circle"/> Cancel
                            </button>

                            <button
                                class="btn btn-primary ml-1"
                                :disabled="processing"
                            >
                                <i
                                    class="fas"
                                    :class="processing ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
                                /> Pay now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Form from 'laravel-form-validation';
import loadScript from 'load-script2';
import CreditCard from '@/js/Components/Shared/Components/CreditCard.vue';
import {calculateGrandTotal} from '@/js/Components/Shared/subscriptionGrandTotal.js';
import {grandTotalTemplate} from '@/js/Components/Shared/grandTotalPreviewTemplate.js';
/*global Stripe*/

export default {
    name: 'PaymentMethodCard',
    props: {
        user: {
            type: Object,
            required: true
        },
        subscription: {
            type: Object,
            required: true,
            default: () => {}
        },
        zipCodes: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            processing: false,
            card: {
                holder: this.user.name,
                address: {},
                element: null,
            },
            stripe: null,
            isDefaultPaymentMethod: false,
            defaultPaymentMethod: {},
            isTaxApplicable: false,
            form: new Form()
        }
    },
    components: {
        CreditCard
    },
    created() {
        this.init();
        this.fetchDefaultPaymentMethod();
    },
    methods: {
        init() {
            loadScript('https://js.stripe.com/v3')
                .then(() => {
                    this.stripe = Stripe(window.appConfig.stripeKey);

                    this.card.element = this.stripe.elements().create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#495057',
                            }
                        }
                    });
                    this.card.element.mount(this.$refs.cardElement);
                })
        },
        async submit() {
            this.processing = true;

            let {isTaxApplicable, taxRate, tax, grandTotal} = calculateGrandTotal(
                this.subscription.price,
                this.zipCodes,
                this.zipCode,
            );

            this.isTaxApplicable = isTaxApplicable;

            let message = grandTotalTemplate(this, this.subscription.price, taxRate, tax, grandTotal);

            const confirm = await this.$bvModal.msgBoxConfirm(message, {
                okVariant: 'primary',
                title: 'Please Confirm Your Subscription',
                okTitle: 'Proceed',
            });

            if (!confirm) {
                this.processing = false;
                return;
            }

            if (this.isDefaultPaymentMethod) {
                this.sendToServer(this.defaultPaymentMethod);
                return;
            }

            const {paymentMethod, error} =  await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card.element,
                billing_details: {
                    name: this.card.holder,
                    email: this.user.email,
                    phone: this.user.phone,
                    address: {
                        line1: this.card.address.line1,
                        line2: this.card.address.line2,
                        city: this.card.address.city,
                        postal_code: this.card.address.postal_code,
                    }
                },
            })

            if (error) {
                this.processing = false;
                this.$toast.error(error.message);
            } else {
                this.sendToServer(paymentMethod);
            }
        },
        sendToServer(paymentMethod) {
            this.form.post(route('admin.subscriptions.subscribe.paid'), {
                payment_method: paymentMethod,
                subscription: this.subscription,
                isTaxApplicable: this.isTaxApplicable,
            })
                .then(() => {
                    this.$toast.success('Congratulations! your subscription has been started.');
                    window.location = route('admin.');
                })
                .catch(() => {
                    this.$toast.error('Something went wrong')
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        cancelPayment() {
            this.$emit('cancelPayment');
        },
        switchPaymentMethod() {
            this.isDefaultPaymentMethod = ! this.isDefaultPaymentMethod;
        },
        fetchDefaultPaymentMethod() {
            this.$http.get(route('admin.default.card'))
                .then((response) => {
                    this.defaultPaymentMethod = response.data;
                })
                .catch(() => {
                    this.$toast.error("Default card doesn't exist");
                })
        }
    },
    computed: {
        zipCode() {
            return this.isDefaultPaymentMethod ?
                this.defaultPaymentMethod.billing_details.address.postal_code : this.card.address.postal_code;
        }
    }
}
</script>
