<template>
    <div class="content-wrapper">
        <section class="content">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h3 class="card-title">Square Locations List</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                                <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2" v-model="params.per_page">
                                    <option disabled>Per page</option>
                                    <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                                </select>

                                <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search" name="search"
                                        v-model.trim="params.search">
                                <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2" :disabled="processing">
                                    <i class="fa fa-search fa-fw"/>
                                </button>
                                <div>
                                    <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing" aria-label="Loading"/>
                                </div>
                            </form>
                            <div class="table-responsive table-wrapper">
                        <table id="table" class="table table-bordered table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th v-for="(column, key, index) in columns" :key="index">
                                        <a href="#" @click="sort(key)">{{ column }}</a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="sortedLocations.length > 0">
                                <tr v-for="(location, index) in sortedLocations" :key="index">
                                    <td>{{ location.name }}</td>
                                    <td>
                                        <ul class="list-unstyled">
                                            <li v-for="(address, index) in location.address" :key="index">{{ address }}</li>
                                        </ul>
                                    </td>
                                    <td>{{ location.timezone }}</td>
                                    <td :class="location.status=='ACTIVE' ? 'text-success text-bold' : 'text-danger text-bold'">{{ location.status=='ACTIVE' ? "Active" : "Inactive"}}</td>
                                    <td>{{ formatDateTime(location.created_at) }}</td>
                                    <td>{{ formatDateTime(location.updated_at) }}</td>
                                </tr>
                            </tbody>
                            <tfoot v-else>
                                <td colspan="6">
                                    <div class="text-center">
                                        <strong>No Results Found</strong>
                                    </div>
                                </td>
                            </tfoot>
                        </table>
                    </div>
                        <div class="text-center">
                                <pagination class="mb-0"
                                            @pagination-change-page="pageNavigated"
                                            :data="response"
                                            :show-disabled="true"
                                            :limit="2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';
import {scrollToTop} from "@/js/scrollToTop.js";

export default {
    name: "LocationIndex",
    components: {
        Pagination
    },
    props: {
        initial: {
            type: Object
        }
    },
    data() {
        return {
            processing: false,
            response: {},
            sortKey: 'name',
            sortOrder: 'asc',
            params: {
                page: 1,
                search: null,
                per_page: 15,
            },
            columns: {'name':'Name', 'address':'Location', 'timezone':'Timezone','status':'Status', 'created_at':'Created At', 'updated_at':'Updated At'}
        }
    },
    created() {
        this.response = this.initial
    },
    methods: {
        sort(sortKey) {
            if (sortKey === this.sortKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            }
            this.sortKey = sortKey;
        },
        pageNavigated(page = 1) {
            this.params.page = page;
            this.fetch();
        },
        fetch() {
            this.processing = true;
            scrollToTop();
            this.$http.get(route('admin.locations.index'), {
                params: this.params,
            })
                .then((response) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() =>{
                    this.processing = false;
                })
        },
        formatDateTime (dateTime) {
            return dayjs(dateTime).format('YYYY-MMM-DD HH:MM:ss a')
        }
    },
    computed: {
        locations: function() {
            return this.response.data
        },
        sortedLocations: function() {
            return this.locations.sort((a, b) => {
                let modifier = 1;
                if (this.sortOrder === "desc") modifier = -1;
                if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
                if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
                return 0;
            });
        }
    }
}
</script>
