<template>
  <div class="modal modal-center fade kitchen-category-add-modal modal-appear"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block; z-index: 1' : 'display: none'"
       id="stepDetailModal"
       ref="stepDetailModal"
       tabindex="-1"
       aria-labelledby="stepDetailModal"
       aria-hidden="true">
    <div class="modal-dialog modal-lg h-30 my-0">
      <div class="modal-content h-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Recipe Step {{ (stepId + 1) }}</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="m-3">
            <div class="row">
              <div class="col-3">
                <div class="img-wrapper">
                  <img :src="recipe.steps[stepId].image" alt="">
                </div>
              </div>
              <div class="col-3 pl-3">
                <h5>Title of the step</h5>
                <p>{{ recipe.steps[stepId].title }}</p>
              </div>
              <div class="col-2 pl-2">
                <h5>Timer</h5>
                <p>{{ this.timers[recipe.steps[stepId].timer] }}</p>
              </div>
              <div class="col-2">
                <h5>Timer in minutes</h5>
                <p>{{ recipe.steps[stepId].timer_in_minutes }}</p>
              </div>
              <div class="col-2 text-right" v-if="showRecipeEditButton">
                <div class="btn-group btn-group-sm">
                  <button class="btn btn-primary mr-2" @click="showStepEditForm(recipe.steps[stepId])" title="edit">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button class="btn btn-danger" @click="deleteStep(recipe.steps[stepId].id)" title="delete">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 my-3 instructions-wrapper">
                <h4>Instructions</h4>
                <p>{{ recipe.steps[stepId].instruction }}</p>
              </div>
            </div>
            <div class="row my-4 table-wrapper">
              <table class="table ingredient-table" v-if="recipe.steps[stepId].ingredients.length > 0">
                <thead>
                  <tr class="bg-light bg-outline-light">
                    <th scope="col">Image</th>
                    <th scope="col">Ingredient Name</th>
                    <th scope="col">UOM</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ingredient) in recipe.steps[stepId].ingredients ">
                    <td>
                      <img :src="ingredient.catalog.image" class="ingredient-image" alt="">
                    </td>
                    <td>{{ ingredient.catalog.name }}</td>
                    <td>
                      {{
                        (Object.keys(ingredient.unit_of_measure).length > 0) ? (ingredient.unit_of_measure.item_variation_data.name + '-'
                          + (ingredient.unit_of_measure.item_variation_data.price_money ? ingredient.unit_of_measure.item_variation_data.price_money.amount/100.00 : '0')) : ''
                      }}
                    </td>
                    <td>{{ ingredient.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'laravel-form-validation';

export default {
  name: 'RecipeStepDetail',
  props: {
    showModal: {
      type: Boolean,
    },
    initial: {
      type: Object,
    },
    step: {
      type: Object,
    },
    stepId: {
      type: Number
    },
    ingredients: {
      type: Array,
    },
    showRecipeEditButton: {
      type: Boolean,
    },
    stepTimers: {
      type: Object,
    },
  },
  data() {
    return {
      stepDetailModalIsVisible: true,
      recipe: {

      },
      form: new Form,
      timers: ['No Control', 'User Timer,Must Wait', 'User Timer,Must Advance']
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeStepDetailModal');
    },
    async deleteStep(id) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this step?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.form.delete(route('admin.kitchen.step.destroy', id))
        .then(() => {
          this.closeModal()
          this.$emit('stepDeleted', this.stepId)
          this.$toast.success("Step has been deleted")
        })
        .catch(error => {
          console.error(error.message)
          this.$toast.error(error.response.data.error)
        })

    },
    showStepEditForm(step) {
      this.$emit('showStepEditForm', step)
      this.closeModal()
    },
  },
  created() {
    this.recipe = this.initial;
  }
}
</script>

<style scoped>
.table-wrapper {
  max-height: 250px;
  overflow: auto;
}
.modal-appear {
  z-index: 1039 !important;
}
.ingredient-image {
  border-radius: 50% !important;
  box-sizing: border-box;
  height: 55px;
  width: 55px;
}
.img-wrapper {
  min-height: 100px !important;
}
.modal-content p {
  font-size: 1rem;
}
.instructions-wrapper p {
  max-height: 75px;
  overflow: auto;
}
</style>
