<template>
    <div class="modal fade catalogue-edit-modal"
         :class="showModal ? 'show' : ''"
         :style="showModal ? 'display: block;' : 'display: none'"
         id="catalogueEditModal"
         ref="catalogueEditForm"
         tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-lg h-100 my-0">
            <div class="modal-content h-100">
                <div class="modal-header d-flex justify-content-between align-items-center">
                    <button
                        type="button"
                        class="close m-0 p-0 position-static"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="closeModal"
                    >
                        <span aria-hidden="true" class="text-dark">&times;</span>
                    </button>
                    <h5 class="modal-title" id="exampleModalLabel">Edit Catalog Item</h5>

                    <div class="btn-wrap">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Back</button>
                        <button type="button" class="btn btn-primary ml-2" @click="submit">Update</button>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="catalogue-edit-form">
                        <div class="card mb-0">
                            <div class="card-body">
                                <form  enctype="multipart/form-data">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col-9">
                                                <h3 class="text-primary">{{ catalog.name  }}</h3>
                                                <p class="text-muted" v-if="catalog.description">{{ catalog.description }}</p>
                                                <p class="text-muted" v-else>Description not available</p>
                                                <div class="row">
                                                    <template v-for="variations in catalog.variations_data">
                                                        <template v-for="variation in variations">
                                                            <div class="col-6" v-if="typeof variation === 'object'">
                                                                <p class="my-0">
                                                                    <strong>{{variationName(variation)}}</strong>
                                                                </p>
                                                                <p class="my-0">
                                                                    <strong>{{variationSku(variation)}}</strong>
                                                                </p>
                                                                <p class="my-0">
                                                                    <strong>{{variationPriceMoney(variation)}}</strong>
                                                                </p>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-3 text-center">
                                                <img
                                                    :src="catalog.image"
                                                    :alt="catalog.name"
                                                    class="rounded-circle catalog-edit-image"
                                                />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <strong>Category</strong>
                                            <p class="text-primary" v-if="catalog.category">{{ catalog.category.category }}</p>
                                        </div>

                                        <div class="form-row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="sell_by_days">Sell By Days</label>
                                                    <div class="input-group date">
                                                        <input
                                                            id="sell_by_days"
                                                            name="sell_by_days"
                                                            type="text"
                                                            class="form-control"
                                                            :class="{ 'is-invalid' : form.$errors.has('sell_by_days')}"
                                                            v-model="catalog.sell_by_days"
                                                        />
                                                        <div
                                                            class="invalid-feedback"
                                                            v-show="form.$errors.has('sell_by_days')"
                                                        >
                                                            {{ form.$errors.first('sell_by_days') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="use_by_days">Use By Days</label>
                                                    <div class="input-group date">
                                                        <input
                                                            id="use_by_days"
                                                            name="use_by_days"
                                                            type="text"
                                                            class="form-control"
                                                            :class="{ 'is-invalid' : form.$errors.has('use_by_days')}"
                                                            v-model="catalog.use_by_days"
                                                        />
                                                        <div
                                                            class="invalid-feedback"
                                                            v-show="form.$errors.has('use_by_days')"
                                                        >
                                                            {{ form.$errors.first('use_by_days') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="packed_date">Packed Date</label>
                                                    <select
                                                        id="packed_date"
                                                        name="packed_date"
                                                        class="custom-select"
                                                        :class="{ 'is-invalid' : form.$errors.has('packed_date')}"
                                                        v-model="catalog.packed_date"
                                                    >
                                                        <option :value="null">Select Option</option>
                                                        <option value="No">No</option>
                                                        <option value="Standard">Standard</option>
                                                        <option value="Coded">Coded</option>
                                                    </select>
                                                    <div
                                                        class="invalid-feedback"
                                                        v-show="form.$errors.has('packed_date')"
                                                    >
                                                        {{ form.$errors.first('packed_date') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ul class="nav nav-pills plan-toggle d-flex justify-content-center align-items-center mb-3">
                                          <li>
                                            <a href="#" :class="{'active': isCustomLabelActive}" data-toggle="pill" @click="showCustomLabel(true)">Show Custom Fields</a>
                                          </li>
                                          <li>
                                            <a href="#" :class="{'active': !isCustomLabelActive}" data-toggle="pill" @click="showCustomLabel(false)">Hide Custom Fields</a>
                                          </li>
                                        </ul>
                                        <div v-if="isCustomLabelActive" class="form-row">
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_1">Custom Field 1</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_1"
                                                  name="custom_field_1"
                                                  type="text"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_1')}"
                                                  v-model="catalog.custom_field_1"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_1')"
                                                >
                                                  {{ form.$errors.first('custom_field_1') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_2">Custom Field 2</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_2"
                                                  name="custom_field_2"
                                                  type="text"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_2')}"
                                                  v-model="catalog.custom_field_2"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_2')"
                                                >
                                                  {{ form.$errors.first('custom_field_2') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_3">Custom Field 3</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_3"
                                                  name="custom_field_3"
                                                  type="text-area"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_3')}"
                                                  v-model="catalog.custom_field_3"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_3')"
                                                >
                                                  {{ form.$errors.first('custom_field_3') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_4">Custom Field 4</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_4"
                                                  name="custom_field_4"
                                                  type="text"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_4')}"
                                                  v-model="catalog.custom_field_4"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_4')"
                                                >
                                                  {{ form.$errors.first('custom_field_4') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_5">Custom Field 5</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_5"
                                                  name="custom_field_5"
                                                  type="text"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_5')}"
                                                  v-model="catalog.custom_field_5"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_5')"
                                                >
                                                  {{ form.$errors.first('custom_field_5') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="custom_field_6">Custom Field 6</label>
                                              <div class="input-group date">
                                                <textarea
                                                  id="custom_field_6"
                                                  name="custom_field_6"
                                                  type="text"
                                                  class="form-control"
                                                  :class="{ 'is-invalid' : form.$errors.has('custom_field_6')}"
                                                  v-model="catalog.custom_field_6"
                                                />
                                                <div
                                                  class="invalid-feedback"
                                                  v-show="form.$errors.has('custom_field_6')"
                                                >
                                                  {{ form.$errors.first('custom_field_6') }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label>Label Formats</label>
                                                    <div class="form-check mb-1">
                                                        <input
                                                            id="clearAll"
                                                            name="no_label"
                                                            type="checkbox"
                                                            v-model="catalog.no_label"
                                                            class="form-check-input"
                                                            :value="catalog.no_label"
                                                            @click="clearAll"
                                                        >
                                                        <label class="form-check-label mt-1" for="clearAll">No Label</label>
                                                    </div>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <label>Default Label Format</label>
                                                </div>
                                            </div>
                                            <div class="row" v-for="labelFormat in labelFormats" :key="labelFormat.id">
                                                <div class="col-6 label-format-wrapper">
                                                    <div class="form-check mb-1">
                                                        <input
                                                            :id="`labelFormat-${labelFormat.id}`"
                                                            name="label_formats[]"
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            :class="{ 'is-invalid' : form.$errors.has('packed_date')}"
                                                            v-model="catalog.label_formats"
                                                            :value="labelFormat.id"
                                                            @click="selectLabelFormat(labelFormat.id)"
                                                        >
                                                        <label
                                                            :for="`labelFormat-${labelFormat.id}`"
                                                            :class="labelFormat.id === catalog.default_label_format ? 'text-danger' : ''"
                                                            class="form-check-label mt-1"
                                                        >
                                                            {{ labelFormat.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <input
                                                        name="default_label_format"
                                                        type="radio"
                                                        :ref="'label-format-radio-button-'+labelFormat.id"
                                                        v-model="catalog.default_label_format"
                                                        :value="labelFormat.id"
                                                        :disabled="!catalog.label_formats.includes(labelFormat.id)"
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-show="
                                                form.$errors.has('label_formats') ||
                                                form.$errors.has('default_label_format')"
                                            >
                                                {{ form.$errors.first('label_formats') || form.$errors.first('default_label_format') }}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="print_statement_id">Print Statement</label>
                                            <select
                                                id="print_statement_id"
                                                name="print_statement_id"
                                                class="custom-select"
                                                :class="{ 'is-invalid' : form.$errors.has('print_statement_id')}"
                                                v-model="catalog.print_statement_id"
                                            >
                                                <option :value="null">None</option>
                                                <option
                                                    v-for="statement in statements"
                                                    :key="statement.id"
                                                    :value="statement.id"
                                                >
                                                    <strong>{{ statement.statement }}</strong>
                                                </option>
                                            </select>
                                            <div
                                                class="invalid-feedback d-block"
                                                v-show="form.$errors.has('print_statement_id')"
                                            >
                                                {{ form.$errors.first('print_statement_id') }}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label>Tare Weight Required</label>
                                                    <div class="form-check mb-2">
                                                        <input
                                                            type="checkbox" id="is_tare_weight_required"
                                                            name="is_tare_weight_included"
                                                            class="form-check-input"
                                                            v-model="catalog.is_tare_weight_included"
                                                        >
                                                        <label for="is_tare_weight_required" class="form-check-label mt-1">
                                                            Require user to enter container tare weight
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Form from 'laravel-form-validation';

export default {
    name: "CatalogueEditModal",
    props: {
        initial: {
            type: Object,
        },
        labelFormats: {
            type: Array,
        },
        statements: {
            type: Array,
        },
        showModal: {
            type: Boolean,
        },
        isCustomFieldEmpty: {
            type: Boolean,
        }
    },
    data() {
        return {
            procesing: false,
            catalog: {
                label_formats: [],
                packed_date : null,
                print_statement_id : null,
            },
            form: new Form(),
            isCustomLabelActive: !this.isCustomFieldEmpty
        }
    },
    methods: {
        submit() {
            this.procesing = true;
            this.form.put(route('admin.catalog.update', this.initial), this.catalog)
                .then((response) => {
                    if (response.status === 202) {
                        this.$toast.error(response.message);
                    } else {
                        this.$toast.success("Catalog updated successfully");
                        this.$emit('closeCatalogueEditModal');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error('Something went wrong')
                })
                .finally(() => {
                    this.procesing = false
                })
        },
        variationName(variation) {
            return 'name' in variation ? `UOM: ${variation.name}` : '-';
        },
        variationSku(variation) {
            return 'sku' in variation ? `SKU: ${variation.sku}` : '-';
        },
        variationPriceMoney(variation) {
            return 'price_money' in variation ? `${variation.price_money.currency}:
            ${this.getFormatedPrice(variation.price_money.amount/100)}` : '-';
        },
        getFormatedPrice(amount) {
            return amount.toFixed(2)
        },
        closeModal() {
            this.form = new Form();
            this.$emit('closeCatalogueEditModal');
        },
        catalogueLabelFormats(selectedLabelFormats) {
            let labelFormats = [];
            labelFormats = selectedLabelFormats.map(item => {
                return item.id;
            });

            return labelFormats;
        },
        catalogDefaultLabelFormat(selectedLabelFormats) {
            let defaultLabelFormat = selectedLabelFormats.find(item => item.pivot.is_primary === "1");

            return defaultLabelFormat ? defaultLabelFormat.id : null;
        },
        clearAll() {
            this.catalog.no_label = ! this.catalog.no_label;
            if (this.catalog.no_label) {
                this.catalog.label_formats = [];
                this.catalog.default_label_format = null;
            }
        },
        selectLabelFormat(labelFormatId) {
            if (this.catalog.label_formats.length === 0) {
                this.catalog.default_label_format = labelFormatId;
            }
        },
      showCustomLabel(isActive) {
        this.isCustomLabelActive = isActive ? true : false;
      }
    },
    watch: {
        initial(newValue) {
            this.catalog = Object.assign({}, newValue, {
                label_formats: this.catalogueLabelFormats(newValue.labels) || [],
                default_label_format: this.catalogDefaultLabelFormat(newValue.labels),
                no_label: false
            });
          this.isCustomLabelActive = !this.isCustomFieldEmpty
        },
    }
}
</script>

<style scoped>

.plan-toggle {
  width: 330px;
  background: #f5f5f5;
  margin: 0;
  border-radius: 28px;
  border: 3px solid #f5f5f5;
  text-align: center;
}

.plan-toggle a {
  padding: 5px;
  font-size: 13px;
  border-radius: 28px;
}

.plan-toggle li {
  float: none;
  width: 50%;
}

.plan-toggle li a.active {
  background: #0E54EA;
  color: #ffffff;
}
.label-format-wrapper {
    word-break: break-word;
}
.catalogue-edit-modal .modal-content {
    overflow: auto;
}
</style>
