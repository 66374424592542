<template>
  <form method="POST" @submit.prevent="submit" enctype="multipart/form-data">
    <div class="card-body">
      <div class="form-group">
        <label for="type_id">Label Type</label>
        <select
          name="type_id"
          id="type_id"
          class="form-control"
          v-model="label.type_id"
          v-invalid="form.$errors"
        >
          <option :value="null">Select Label Type</option>
          <option
            v-for="item in labels"
            :value="item.id"
            :key="item.id"
          >
            {{ item.type }}
          </option>
        </select>
        <field-error :bag="form.$errors" field="type_id"/>
      </div>

      <div class="form-group">
        <label for="name">Label Format Name</label>
        <input
          id="name"
          name="name"
          type="text"
          class="form-control"
          v-invalid="form.$errors"
          placeholder="Enter Label Format Name"
          v-model="label.name"
        >
        <field-error :bag="form.$errors" field="name"/>
      </div>

      <div class="form-group">
        <label for="name">Description Count</label>
        <input
          id="description_count"
          name="description_count"
          type="text"
          class="form-control"
          v-invalid="form.$errors"
          placeholder="Enter Description Count"
          v-model="label.description_count"
        >
        <field-error :bag="form.$errors" field="description_count"/>
      </div>

      <div class="form-group">
        <label for="category_id">Label Format Type</label>
        <select
          name="category_id"
          id="category_id"
          class="form-control"
          v-model="label.category_id"
          v-invalid="form.$errors"
        >
          <option :value="null">Select Label Format Type</option>
          <option v-for="label_category in labelCategories" :value="label_category.id" :key="label_category.id"> {{label_category['name']}} </option>
        </select>
        <field-error :bag="form.$errors" field="category_id"/>
      </div>

      <div class="form-group">
        <label for="zpl_data">ZPL Data</label>
        <textarea class="form-control" name="zpl_data" id="zpl_data" rows="6" v-model="label.zpl_data" v-invalid="form.$errors">
                                </textarea>
        <field-error :bag="form.$errors" field="zpl_data"/>
      </div>

      <div class="form-group">
        <label for="image">Label Image</label>
        <b-form-file
          id="image"
          name="image"
          type="file"
          :state="form.$errors.has('image') ? false : null"
          v-model="label.image"
          placeholder="Choose File"
          accept="image/*"
        />
        <field-error :bag="form.$errors" field="image"/>
      </div>

      <div class="form-group add-multiselect-wrapper">
        <label for="merchants">Assign Merchants</label>
        <multiselect
          id="merchants"
          name="merchants"
          label="display_name"
          track-by="id"
          v-model="label.merchants"
          :options="merchantsOptions"
          :multiple="true"
          :taggable="false"
          :searchable="true"
          :close-on-select="false"
          placeholder="Select Merchants"
        />
        <field-error :bag="form.$errors" field="merchants" class="d-block"/>
      </div>

      <div class="form-group">
        <label for="status">Status</label>
        <select
          name="status"
          id="status"
          class="form-control"
          v-model="label.status"
          v-invalid="form.$errors"
        >
          <option :value="null">Select Status</option>
          <option :value="1">Active</option>
          <option :value="0">InActive</option>
        </select>
        <field-error :bag="form.$errors" field="status"/>
      </div>

      <div class="form-group">
        <button type="submit" :disabled="form.$pending" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</template>

<script>

import Form from 'laravel-form-validation';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'CreateCustomLabelFormat',
  props: {
    labels: {
      type: Array,
    },
    merchants: {
      type: Array,
    },
    labelCategories : {
      type: Array,
    }
  },
  data: () => ({
    label: {
      type_id: null,
      image: null,
      merchants: [],
      status: null,
      zpl_data : null,
      category_id : null,
      description_count : null,
    },
    labelCategories: {
      id : null
    },
    form: new Form(),
  }),
  methods: {
    submit() {
      this.form.post(route('admin.custom-label-format.store'), this.label)
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            window.location = route('admin.custom-label-format.index');
            this.$toast.success('Label Format Created successfully');
          }
        })
    },
    onChange(event) {
      let files = event.target.files;

      // Drag and Drop support
      if (!files.length) {
        files = event.dataTransfer.files
      }

      if (files.length) {
        const reader = new FileReader();

        reader.onload = e => {
          this.imageUrl = e.target.result;
        }

        this.$emit('input', files[0]);
        this.image = files[0]
        reader.readAsDataURL(files[0]);
      } else {
        this.$emit('input', null);
      }
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    merchantsOptions() {
      return this.merchants.map(item => {
        item.display_name = item.profile.business_name;
        return item;
      });
    }
  }
}
</script>
