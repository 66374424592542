<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <h2 class="card-title mb-1 ml-2">Recipe WorkList</h2>
            </div>
            <div class="card-body">
              <div class="row align-items-start mb-3">
                <div class="col-12">
                  <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                            v-model="params.per_page">
                      <option disabled>Per page</option>
                      <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <b-dropdown variant="light" left class="">
                      <template #button-content>
                        <span>
                          All Categories
                        </span>
                      </template>
                      <div class="recipe-list">
                        <div class="form-group" v-if="categories.length === 0">
                          <label class="form-check-label ml-4">The list is empty.</label>
                        </div>
                        <div v-for="(category) in categories" :key="category.id" class="dropdown-item">
                          <div class="form-group">
                            <input type="checkbox"
                                   class="form-check-input"
                                   v-model="params.category_ids"
                                   :value="category.id"
                                   :id="`category-${category.id}`"
                            >
                            <label class="form-check-label"
                                   :for="`category-${category.id}`">{{ category.name }}</label>
                          </div>
                        </div>
                      </div>
                    </b-dropdown>
                    <div class="recipe-list date-filter">
                      <div>
                        <label for="">From Date</label>
                        <input type="date" name="from_date" v-model="params.from_date" class="mb-2">
                      </div>
                      <div class="ml-2">
                        <label for="">To Date</label>
                        <input type="date" name="to_date" v-model="params.due_date" class="mb-2">
                      </div>
                    </div>

                    <input type="text"
                           class="form-control col-3 mb-sm-0"
                           placeholder="Search"
                           v-model="params.search"
                           name="search">
                    <button type="submit"
                            class="btn btn-primary mb-0 mx-2">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </form>
                </div>
                <div class="col-6 text-right">
                  <!--                  <button class="btn btn-primary btn-small btn-secondary mr-2" @click="toggleModalState('showRecipeDownloadModal')" :disabled="selectedRecipes.length === 0">Download</button>-->
                  <!--                  <button class="btn btn-primary btn-small" @click="createRecipe">+ Create New Recipe</button>-->
                </div>
              </div>
              <div class="row align-items-center mb-3">
                <div class="col-12">
                  <div class="table-wrapper table-responsive">
                    <table id="table" class="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th class="text-primary">
  <!--                          <span class="mr-2">-->
  <!--                            <input type="checkbox" v-model="allSelected" name="selectAll"/>-->
  <!--                          </span>-->
                            Image
                          </th>
                          <th class="text-primary">Name</th>
                          <th class="text-primary">Recipe Yield</th>
                          <th class="text-primary">Category</th>
                          <th class="text-primary">UOM</th>
                          <th class="text-primary">Batch Size</th>
                          <th class="text-primary">Due Date</th>
                          <th class="text-primary">Assigned Employee</th>
                          <th class="text-primary">Status</th>
                          <th class="text-primary">
                            <a href="#">Actions</a>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="recipes.length > 0">
                        <tr v-for="(recipe) in recipes">
                          <td>
                            <img :src="recipe.recipe.image" alt="">
                          </td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">{{ recipe.recipe.name }}</td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">{{ recipe.recipe.catalog ? recipe.recipe.catalog.name : '' }}</td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">{{ recipe.recipe.recipe_category.name }}</td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">
                            {{
                              getUnitOfMeasure(recipe.recipe.catalog, recipe.recipe.variation_data_id)
                            }}
                          </td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">{{ recipe.batch_size }}</td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">{{ recipe.due_date }}</td>
                          <td :class="{ 'text-danger': isDueDateExpired(recipe.due_date) && !isRecipeCompleted(recipe) }">
                            {{ (recipe.open_to_all_employees) ? 'Open To All Employees' : '' }}
                            {{ (recipe.employee) ? recipe.employee.name : '' }}
                          </td>
                          <td>
                            <span class="text-sm badge badge-pill" :class="statusClasses[getRecipeStatus(recipe.status)]"> {{ getRecipeStatus(recipe.status).replaceAll('_',' ') }}  </span>
                          </td>
                          <td>
                            <a data-toggle="dropdown" id="ellipsis-icon" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-h"/>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
                              <span v-if="recipe.status === workListStatus.OPEN" class="dropdown-item" @click="editRecipe(recipe)">
                                Edit Batch Details
                              </span>
                              <span v-if="recipe.status !== workListStatus.OPEN" class="dropdown-item" @click="editRecipe(recipe)">
                                View Batch Details
                              </span>
                              <span v-if="recipe.status === workListStatus.OPEN" class="dropdown-item" @click="removeRecipe(recipe)">
                                Remove
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-else>
                        <td colspan="10">
                          <div class="text-center">
                            <strong>No Results Found</strong>
                          </div>
                        </td>
                      </tfoot>
                    </table>
                  </div>
                  <section class="text-center">
                    <div class="table-responsive-sm">
                      <pagination class="mb-0"
                                  @pagination-change-page="pageNavigated"
                                  :show-disabled="true"
                                  :data="response"
                                  :limit="2"/>
                      <button class="btn btn-primary" v-if="selectedRecipes.length > 0" >Remove</button>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <work-list-edit-modal v-if="showEditWorkListModal"
                            :show-modal="true"
                            :employees="employees"
                            :recipe-data="recipe"
                            :work-list-status="workListStatus"
                            @closeModal="closeModal"
                            @batchDetailsUpdated="fetch"
      />
    </section>
  </div>
</template>

<script>

import Form from 'laravel-form-validation';
import Pagination from 'laravel-vue-pagination';
import WorkListEditModal from '@/js/KitchenHand/Components/WorkList/WorkListEditModal';
import dayjs from "dayjs";

export default {
  name: 'WorkListIndex',
  components: {Pagination, WorkListEditModal},
  props: {
    initial: {
      type: Object,
    },
    employees: {
      type: Object,
    },
    categories: {
      type: Array,
    },
    recipeYields: {
      type: Array,
    },
    workListStatus: {
      type: Object,
    },
  },
  data() {
    return {
      params: {
        search: '',
        per_page: 15,
        category_ids: [],
        from_date: '',
        due_date: '',
      },
      recipe: {},
      response: {},
      form: new Form,
      showEditWorkListModal: false,
      selectedRecipes: [],
      allSelected: false,
      statusClasses: {
        'OPEN': 'badge-success',
        'IN_PROGRESS': 'badge-warning',
        'COMPLETED' :'badge-primary',
      },
    }
  },
  created() {
    this.response = this.initial
  },
  methods: {
    editRecipe(recipe) {
      this.recipe = recipe
      this.showEditWorkListModal = true
    },
    closeModal() {
      this.showEditWorkListModal = false
    },
    fetch() {
      this.$http.get(route('admin.kitchen.work-list.index'), {
        params: this.params
      })
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          this.$toast.error('Something went wrong');
        })
    },
    async removeRecipe(recipe) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to remove this recipe from the work list' , {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Remove',
        cancelTitle: 'Cancel'
      })

      if (!confirm) {
        return ;
      }

      this.form.delete(route('admin.kitchen.work-list.destroy', recipe.id))
        .then(() => {
          this.$toast.success('Recipe has been removed from the work list.')
          this.fetch()
        })
        .catch(error => {
          console.error(error.message)
        })
    },
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      return variationsData.item_variation_data['name'] + '-' + (variationsData.item_variation_data.price_money ? variationsData.item_variation_data.price_money['amount']/100.00 : '0')
    },
    getRecipeStatus(status) {
      return Object.keys(this.workListStatus).find((key) => this.workListStatus[key] === status)
    },
    isDueDateExpired(date) {
      return dayjs(date).format('YYYY-MM-DD') < dayjs(new Date()).format('YYYY-MM-DD')
    },
    isRecipeCompleted(recipe) {
      return recipe.status === this.workListStatus.COMPLETED;
    }
  },
  computed: {
    recipes: function () {
      return this.response.data;
    }
  },
  watch: {
    allSelected: function (newValue) {
      if(newValue === true) {
        this.selectedRecipes = this.recipes.map((recipe) => recipe.id);
      }else{
        this.selectedRecipes = [];
      }
    },
  }
}
</script>

<style scoped>
td > a > i {
  cursor: pointer;
}

.badge-pill {
  color: white;
}


</style>
