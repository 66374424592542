import {currency} from "@/js/currency";

export function grandTotalTemplate(tempObject, price, taxRate, tax, grandTotal) {
    const h = tempObject.$createElement;
    const messageTemplate = h('table', { class: ['table', 'table-striped', 'table-bordered'] }, [
        h('tr', [
            h('td', [
                'Subscription Amount',
            ]),
            h('td', { class: ['text-right'] }, [
                `$${price}`
            ])
        ]),
        h('tr', [
            h('td', [
                `PA State Sales Tax (${taxRate}%)`,
            ]),
            h('td', { class: ['text-right'] }, [
                `$${tax}`
            ])
        ]),
        h('tr', { class: ['bg-light font-weight-bold'] }, [
            h('td', [
                'Grand total',
            ]),
            h('td', { class: ['text-right'] }, [
                `$${grandTotal}`
            ])
        ]),

    ])

    return messageTemplate;
}
