<template>
  <div class="modal modal-center fade ingredient-edit-modal edit-status-modal"
       :class="showModal ? 'show' : ''"
       :style=" 'display: block;' "
       id="ingredientEditModal"
       ref="ingredientEditModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Edit Status</h5>
          <button v-if="showCancelButton"
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="text-dark" @click="closeModal(false)">&times;</span>
          </button>
        </div>
        <div class="modal-body px-0">
          <p>Do you want to Approve this recipe?</p>
          <div class="container px-3">
            <div class="row">
              <div class="col-12">
                
                <div class="form-group text-right mb-0 pt-3">
                  <button class="btn btn-secondary mr-2" @click="approveRecipe(false)">Disapprove</button>
                  <button class="btn btn-primary" @click="approveRecipe(true)">Approve</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>


<script>
export default {
  name: 'RecipeApproveModal',
  props: {
    showModal: {
      type: Boolean,
    },
    initial: {
      type: Object,
    },
    showCancelButton: {
      type: Boolean,
    }
  },
  data() {
    return {
      recipe: this.initial.id
    }
  },
  methods: {
    closeModal(reload) {
      this.$emit('closeModal')
      if (reload) {
        window.location.href = route('admin.kitchen.recipe.index');
      }
    },
    approveRecipe(isApproved) {
      this.$http.put(route('admin.kitchen.approve-recipe', this.recipe), {status: isApproved})
        .then((response) => {
          (response.data.is_approved) ? this.$toast.success('Recipe Approved Successfully.') : this.$toast.success('Recipe Disapproved Successfully.')
          this.closeModal(true)
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error(error.response.data.errors.status[0])
          this.closeModal(false)
        })
    }
  }
}
</script>

<style scoped>
.edit-status-modal .modal-body p {
  padding: 0 15px 15px 15px;
  margin: 0;
  border-bottom: 1px solid #e9ecef;
}
</style>
