<template>
  <div class="card mt-2">
    <div class="modal modal-center fade kitchen-history-modal modal-appear"
         :class="showModal ? 'show' : ''"
         :style="showModal ? 'padding-right: 14px; display: block; z-index: 1' : 'display: none'"
         id="showHistoryModal"
         ref="showHistoryModal"
         tabindex="-1"
         aria-labelledby="showHistoryModal"
         aria-hidden="true">
      <div class="modal-dialog modal-md h-30 my-0">
        <div class="modal-content w-100">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <h5 class="modal-title" id="exampleModalLabel">Recipe : {{ history.historyable.name }}</h5>
            <button
              type="button"
              class="close m-0 p-0 position-static"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <span aria-hidden="true" class="text-dark">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="m-3">
              <div class="row" v-if="revisions">
                <div class="col-12" v-for="(value, property) in revisions" :key="property">
                  <div v-if="property === 'recipe'">
                    <h5>Recipe Changes</h5>
                    <div class="mx-3 bg-light" v-for="(recipeValue, recipeProperty) in value" :key="recipeProperty">
                      <p v-if="recipeProperty !== 'image'">{{ recipeProperty.toUpperCase() }} : {{ recipeValue }}</p>
                      <div class="img-wrapper my-3" v-else>
                        <img :src="recipeValue" alt="">
                      </div>
                    </div>
                  </div>
                  <div v-else-if="property === 'step'">
                    <h5>Step Changes</h5>
                    <div v-for="(step) in value">
                      <div v-for="(revisionValue, revisionProperty) in step" :key="revisionProperty" class="mx-3 bg-light">
                        <p v-if="revisionProperty !== 'changes'">{{ revisionProperty.toUpperCase() }} : {{ revisionValue }}</p>
                        <div v-if="revisionProperty === 'changes'">
                          <div v-for="(stepValue, stepProperty) in revisionValue" :key="stepProperty" class="mx-3 my-3 bg-light">
                            <p v-if="stepProperty !== 'image' && stepProperty !== 'ingredients'">{{ stepProperty.toUpperCase() }} : {{ stepValue }}</p>
                            <div class="img-wrapper" v-if="stepProperty === 'image'">
                              <img :src="stepValue" alt="step-image">
                            </div>
                            <div v-if="stepProperty === 'ingredients'">
                              <p>INGREDIENTS : </p>
                              <div v-for="(stepIngredient) in stepValue" class="mx-3 my-3 py-2 bg-gray">
                                <div v-for="(stepIngredientValue, stepIngredientProperty) in stepIngredient" :key="stepIngredientProperty" class="mx-3 my-3">
                                  <p>{{ stepIngredientProperty.toUpperCase() }} : {{ stepIngredientValue }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Ingredient Changes</h5>
                    <div v-for="(revisionValue, revisionProperty) in value" :key="revisionProperty">
                      <p v-if="revisionProperty !== 'changes'">{{ revisionProperty.toUpperCase() }} : {{ revisionValue }}</p>
                      <div class="mx-3 bg-light" v-if="revisionProperty === 'changes'">
                        <div v-for="(ingredientValue, ingredientProperty) in revisionValue" :key="ingredientProperty">
                          <p>{{ ingredientProperty.toUpperCase() }} : {{ ingredientValue }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'HistoryShow',
  props: {
    history: {
      type: Object,
    },
    showModal: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
    }
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-history-modal', this.history.historyable, this.isAdmin);
    },
  },
  computed: {
    revisions: function() {
      return JSON.parse(this.history.revisions);
    }
  },
}
</script>
<style scoped>
.kitchen-history-modal .modal-content{
  max-height: 75vh;
  overflow: auto;
}
.modal-appear {
  z-index: 1039 !important;
}
.img-wrapper {
  min-height: 100px !important;
}
.modal-content p {
  margin-bottom: 10px;
  font-size: 1rem;
}
</style>