import KitchenCategoryIndex from "@/js/KitchenHand/Components/Category/KitchenCategoryIndex";

require('./bootstrap')

import SubscriptionIndex from "@/js/Components/Subscription/SubscriptionIndex.vue";
import ToggleOtpVerification from '@/js/Components/Account/ToggleOtpVerification.vue';
import BillingDetail from "@/js/Components/Account/BillingDetail.vue";
import UserIndex from "@/js/Components/AppUser/UserIndex.vue";
import AdminIndex from "@/js/Components/Admin/AdminIndex.vue";
import LocationIndex from "@/js/Components/Location/LocationIndex.vue";
import StoreIndex from "@/js/Components/Store/StoreIndex.vue";
import PrintStatementIndex from "@/js/Components/PrintStatement/PrintStatementIndex.vue";
import RoleIndex from "@/js/Components/Role/RoleIndex.vue";
import LabelFormatIndex from "@/js/Components/LabelFormat/LabelFormatIndex.vue";
import CustomLabelFormatIndex from "@/js/Components/CustomLabelFormat/CustomLabelFormatIndex.vue";
import CreateCustomLabelFormat from "@/js/Components/CustomLabelFormat/CreateCustomLabelFormat.vue";
import EditCustomLabelFormat from "@/js/Components/CustomLabelFormat/EditCustomLabelFormat.vue";
import CatalogueIndex from "@/js/Components/Catalogue/CatalogueIndex.vue";
import MerchantIndex from "@/js/Components/Merchant/MerchantIndex.vue";
import CatalogueImageCard from "@/js/Components/Catalogue/CatalogueImageCard.vue";
import UpgradeSubscription from "@/js/Components/Account/UpgradeSubscription.vue";
import ItemUpdate from "@/js/Components/MassUpdate/ItemUpdate.vue";
import CategoryIndex from "@/js/Components/Category/CategoryIndex.vue";
import EmployeeIndex from "@/js/KitchenHand/Components/Employee/EmployeeIndex";
import RecipeIndex from "@/js/KitchenHand/Components/Recipe/RecipeIndex";
import RecipeCreate from "@/js/KitchenHand/Components/Recipe/RecipeCreate";
import WorkListIndex from '@/js/KitchenHand/Components/WorkList/WorkListIndex';
import History from '@/js/KitchenHand/Components/History/History';
import CookHistory from '@/js/KitchenHand/Components/History/Cook/CookHistory';

const app = new Vue({
    components: {
        SubscriptionIndex,
        ToggleOtpVerification,
        BillingDetail,
        UserIndex,
        AdminIndex,
        LocationIndex,
        StoreIndex,
        PrintStatementIndex,
        RoleIndex,
        LabelFormatIndex,
        CustomLabelFormatIndex,
        CreateCustomLabelFormat,
        EditCustomLabelFormat,
        CatalogueIndex,
        MerchantIndex,
        CatalogueImageCard,
        UpgradeSubscription,
        ItemUpdate,
        CategoryIndex,
        KitchenCategoryIndex,
        EmployeeIndex,
        RecipeIndex,
        RecipeCreate,
        WorkListIndex,
        History,
        CookHistory
    },
});

// May be some pages doesn't have app container to mount vue
if (document.querySelector('#delilabel-app')) {
    app.$mount('#delilabel-app');
}
