<template>
    <div
        class="card bg-secondary rounded-lg shadow-sm mb-2 mt-2"
    >
        <div
            class="card-body text-light"
            v-if="Object.keys(paymentMethod).length">
            <div class="row">
                <div class="col-6">
                    <i :class="getCardIconClass(paymentMethod.card.brand)" class="fa-2x"/>
                </div>
                <div class="col-6 text-right">
                                        <span
                                            class="badge-pill bg-success shadow text-light badge-light"
                                        >
                                            <small>Default</small>
                                        </span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h3 class="text-left text-monospace mb-2 mt-2">
                        <span class="mr-3">xxxx</span>
                        <span class="mr-3">xxxx</span>
                        <span class="mr-3">xxxx</span>{{ paymentMethod.card.last4 }}
                    </h3>
                </div>
                <div class="col-6 text-right my-auto">
                    Saved:
                    <span> {{ timestampToDate(paymentMethod.created) }} </span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h5 class="mb-0">
                        {{ paymentMethod.billing_details.name || 'NA' }}
                    </h5>
                </div>
                <div class="col-6 text-right my-auto">
                    <h6 class="mb-0">
                        Valid till: {{ getValidTill(paymentMethod.card) }}
                    </h6>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {CardIcons} from '@/js/Components/Shared/CardIcons';
import dayjs from "dayjs";

export default {
    name: "CreditCard",
    props: {
        paymentMethod: {
            type: Object,
            required: true
        }
    },
    methods: {
        timestampToDate(timestamp) {
            return dayjs(dayjs(timestamp * 1000).toJSON()).fromNow();
        },
        getCardIconClass(brand) {
            return CardIcons[brand] || CardIcons.unknown;
        },
        getValidTill(card) {
            return `${card.exp_year} / ${card.exp_month < 10 ? card.exp_month.toString().padStart(2, '0') : card.exp_month}`;
        },
    }
}
</script>
