<template>
    <b-modal
        id="edit-merchant-modal"
        size="md sm"
        title="Extend merchant trial period"
    >
        <div class="form-group mb-2 required" v-if="user">
            <label for="input-trial_ends_at">Trial ends at</label>
            <div class="input-group">
                <flat-pickr
                    id="input-trial_ends_at"
                    v-model="user.trial_ends_at"
                    :config="startConfig"
                    class="form-control"
                    :class="{ 'is-invalid' : form.$errors.has('trial_ends_at')}"
                    placeholder="Select trial end date"
                    name="trial_ends_at"
                />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary"
                            type="button"
                            title="Toggle"
                            :class="{ 'is-invalid' : form.$errors.has('trial_ends_at')}"
                            data-toggle>
                        <i class="fa fa-calendar-alt"/>
                    </button>
                </div>
                <div
                    class="invalid-feedback"
                    v-show="form.$errors.has('trial_ends_at')"
                >
                    {{ form.$errors.first('trial_ends_at') }}
                </div>
            </div>
        </div>
        <template #modal-footer="{ cancel }">
            <button class="btn btn-secondary" @click="cancel">
                Cancel
            </button>
            <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
                @click="submit"
            >
                Update
            </button>
        </template>
    </b-modal>
</template>

<script>
import Form from 'laravel-form-validation';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import {utcToZoned, zonedToUtc} from '@/js/Components/Shared/datatime';
import dayjs from "dayjs";

export default {
    name: "MerchantEditModal",
    components: {
        flatPickr,
    },
    props: {
        initial: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            processing: false,
            startConfig: {
                wrap: true,
                disableMobile: true,
                minDate: dayjs().toString(),
                maxDate: null,
                enableTime: true,
                altFormat: 'M j, Y G:i K',
                altInput: true,
                dateFormat: 'Y-m-d H:i:S',
                plugins: [new ConfirmDatePlugin()],
            },
            user: {},
            form: new Form(),
        }
    },
    methods: {
        submit() {
            this.processing = true;
            this.form.put(route('admin.merchant.extend.trial', this.initial), this.getPayload())
                .then(() => {
                    this.$toast.success('Trial subscription extended');
                    this.$emit('updated');
                })
                .catch((error) => {
                    console.error(error);
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        getPayload() {
            let user = Object.assign({}, this.user)
            user.trial_ends_at = zonedToUtc(user.trial_ends_at, this.timezone)
            return user;
        }
    },
    computed: {
        timezone() {
            return window.appConfig.userTimezone;
        }
    },
    watch: {
        initial(newValue) {
            this.user = Object.assign({}, newValue, {
                'trial_ends_at': utcToZoned(newValue.trial_ends_at, this.timezone),
            });
        }
    }
}
</script>
