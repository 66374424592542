<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <h2 class="card-title mb-1 ml-2">Recipe Categories</h2>
            </div>
            <div class="card-body">
              <div class="row input-header mb-3">
                <div class="col-xl-8">
                  <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                            v-model="params.per_page">
                      <option disabled>Per page</option>
                      <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <input type="text"
                           class="form-control col-6 mb-2 mb-sm-0 mr-sm-2"
                           placeholder="Search"
                           name="search"
                           v-model.trim="params.search">
                    <button type="submit"
                            class="btn btn-primary mb-0 mb-sm-0 mr-sm-2"
                            :disabled="processing">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </form>
                </div>
                <div class="col-xl-4 button-group">
                  <a class="btn btn-primary btn-small" @click="showAddCategoryModal()">+ Add New Category</a>
                </div>
              </div>
              <div class="table-wrapper table-responsive">
              <table id="table" class="table table-bordered table-striped table-hover">
                <thead>
                <tr>
                  <th v-for="(column, key, index) in columns" :key="index">
                    <a href="#" @click="sort('name')"> {{ column }} </a>
                  </th>
                  <th>
                    <a href="#">Action</a>
                  </th>
                </tr>
                </thead>
                <tbody v-if="categories.length > 0">
                <tr v-for="(category, index) in categories" :key="index">
                  <td>
                    <img :src="category.image" alt="">
                  </td>
                  <td>
                    {{ category.name }}
                  </td>
                  <td>
                    {{ formatDateTime(category.created_at) }}
                  </td>
                  <td>
                    {{ formatDateTime(category.updated_at) }}
                  </td>
                  <td>
                    <div class="btn-group btn-group-sm">
                      <a title="Edit"  class="btn btn-primary" @click="showEditCategoryModal(category, index)"><i class="fas fa-edit"></i></a>
                      <a title="Delete" href="#"  class="btn btn-danger ml-1" @click="deleteCategory(category)"><i class="fas fa-trash"></i></a>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot v-else>
                <td colspan="8">
                  <div class="text-center">
                    <strong>No Results Found</strong>
                  </div>
                </td>
                </tfoot>
              </table>
              </div>
              <section class="text-center">
                <div class="table-responsive-sm">
                  <pagination class="mt-3"
                              @pagination-change-page="pageNavigated"
                              :data="response"
                              :show-disabled="true"
                              :limit="2"/>
                </div>
              </section>
            </div>
          </div>

        </div>
      </div>
    </section>

    <kitchen-category-add-modal
      :show-modal="isVisible"
      :auth-user="authUser"
      @closeKitchenCategoryAddModal="closeKitchenCategoryAddModal"
    >

    </kitchen-category-add-modal>

    <kitchen-category-edit-modal
      :initial="selectedCategory"
      :show-modal="categoryEditModalIsVisible"
      :auth-user="authUser"
      @closeKitchenCategoryEditModal="closeKitchenCategoryEditModal"
    >

    </kitchen-category-edit-modal>
  </div>


</template>

<script>


import KitchenCategoryAddModal from "@/js/KitchenHand/Components/Category/KitchenCategoryAddModal";
import KitchenCategoryEditModal from "@/js/KitchenHand/Components/Category/KitchenCategoryEditModal";
import Pagination from "laravel-vue-pagination";
import Form from "laravel-form-validation";
import dayjs from "dayjs";

export default {
  name: "KitchenCategoryIndex.vue",
  components: {
    KitchenCategoryAddModal,
    KitchenCategoryEditModal,
    Pagination,
  },
  data() {
    return {
      processing: false,
      columns: {'image': 'Image', 'category':'Category' ,'created_at': 'Created At', 'updated_at': 'Updated At'},
      selectedCategory: {

      },
      isVisible: false,
      categoryEditModalIsVisible: false,
      params: {
        page: 1,
        search: "",
        per_page: 15,
        category_ids: [],
        sortBy: 'name',
        orderBy: 'asc',
      },

      response: {},
      sortKey: "updated_at",
      sortOrder: "asc",
      form: new Form(),
    }
  },
  props: {
    authUser: {
      type: Object,
    },
    initial: {
      type: Object,
    },
  },

  methods: {
    showAddCategoryModal() {
      this.isVisible = true;
    },

    closeKitchenCategoryAddModal() {
      this.isVisible = false;
    },

    showEditCategoryModal(category, index) {
      this.selectedCategory = category;
      this.categoryEditModalIsVisible = true;
      this.selectedCategory = Object.assign({}, category, {
        _index: index
      })
    },

    closeKitchenCategoryEditModal(category, index) {
      this.categoryEditModalIsVisible = false;
      if (category) {
        this.$set(this.categories, index, Object.assign(this.categories[index], category))
      }
    },

    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },

    fetch() {
      this.processing = true;
      this.$http.get(route('admin.kitchen.category.index'), {
        params: this.params,
      })
        .then((response) => {
          this.response = response.data
        })
        .catch((error) => {
          this.$toast.error('Something went wrong');
        })
        .finally(() => {
          this.processing = false;
        })
    },

    async deleteCategory(category) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this category?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.form.delete(route('admin.kitchen.category.destroy', category.id))
        .then(() => {
          this.$toast.success('Category has been deleted')
          setTimeout(function() { location.reload() }, 1000)
        })
        .catch((error) => {
          this.$toast.error('You cannot delete this category as it is used in any recipe')
        })

    },

    sort(sortKey) {
      console.log(sortKey);
      if (sortKey === this.sortKey) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      }
      this.sortKey = sortKey;
    },

    formatDateTime(dateTime) {
      return dayjs(dateTime).format('YYYY-MM-DD')
    }
  },
  created() {
    this.response = this.initial
    console.log(this.response)
  },
  computed: {
    categories: function () {
      return this.response.data;
    }
  }
}
</script>

<style>
  td img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
</style>
