<template>
  <b-tabs class="mt-2" lazy>
    <a :href="route('admin.subscriptions.index')" class="redirection-btn btn btn-dark" v-if="!canAddSubscription || inTrial">Add Subscription</a>
    <subscription-tab
        :initial="initial"
    />
    <card-tab/>
    <invoice-tab/>
<!--<a href="#" class="test ml-auto">Test</a>-->
  </b-tabs>
</template>

<script>
import SubscriptionTab from '@/js/Components/Account/Tabs/SubscriptionTab';
import InvoiceTab from '@/js/Components/Account/Tabs/InvoiceTab';
import CardTab from '@/js/Components/Account/Tabs/CardTab';

export default {
    name: 'BillingDetail',
    props: {
        initial: {
            type: Object,
            required: true,
        },
        canAddSubscription: {
          type: Object,
          required: true
        },
        inTrial: {
          type: Boolean,
          required:true,
        }
    },
    components: {
        CardTab,
        SubscriptionTab,
        InvoiceTab,
    },
}
</script>

<style>
 .redirection-btn {
   position: absolute;
   top: 8px;
   right: 30px;
   font-size: 12px;
 }
</style>
