function taxRate () {
    return Number(window.appConfig.taxRate);
}

function isTaxApplicable(zipCodes, zipCode) {
    let taxableZipCodes = zipCodes.pennsylvania;
    return taxableZipCodes.includes(Number(zipCode));
}

function taxAmount(price, isTaxApplicable) {
    let tax = 0;
    if (isTaxApplicable) {
        tax = (price * taxRate() / 100).toFixed(2)
    }

    return  Number(tax);
}

export function calculateGrandTotal(price, zipCodes, zipCode) {
    let taxRequired = isTaxApplicable(zipCodes, zipCode);
    let tax = taxAmount(price, taxRequired);
    let grandTotal =  parseFloat(price) + tax;

    return {
        isTaxApplicable: taxRequired,
        taxRate: taxRate(),
        tax: tax.toFixed(2),
        grandTotal: grandTotal.toFixed(2),
    }
}


