<template>
    <div>
        <img
            :src="catalog.image"
            :alt="catalog.name"
            class="rounded-circle"
            :class="thumbnailClass"
            @click="showModal(catalog.image, catalog.name)"
        />
        <b-modal ref="image-modal" hide-footer hide-header centered>
            <i class="fas fa-times float-right close" @click="closeModal()"></i>
            <img
                :src="modalImage"
                :alt="modalImageName"
                class="catalog-modal-class image-modal" />
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "CatalogueImageCard",
    props: {
        catalog: Object,
        thumbnailClass: String,
    },
    data() {
        return {
            modalImage: "",
            modalImageName: "",
        }
    },
    methods: {
        showModal(imageUrl, imageName) {
            this.modalImage = imageUrl;
            this.modalImageName = imageName;
            this.$refs['image-modal'].show();
        },
        closeModal() {
            this.$refs['image-modal'].hide();
        }
    }
}
</script>
