<template>
  <div class="card mt-2">
    <div class="card-header">
      <h3 class="card-title">OTP verification for App User</h3>
    </div>
    <div class="card-body">
        <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="toggle-button" v-model="admin.is_otp_enabled" @change="toggleOtpVerificationSetting()">
            <label class="custom-control-label" for="toggle-button">Toggle this switch to enable OTP verification</label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleOtpVerification",
  props: {
    initial: {
        type: Object,
        required: true,
    },
  },
  data() {
    return {
      admin: this.initial,
    }
  },
  methods: {
    toggleOtpVerificationSetting() {
      this.$http.put(route('admin.account.toggleOtpVerificationSetting'), {
        admin:this.admin
      })
          .then((response) => {
              if (this.admin.is_otp_enabled) {
                  this.$toast.success("OTP verification is enabled");
              } else {
                  this.$toast.success("OTP verification is disabled");
              }
          })
          .catch((error) => {
              this.$toast.error('Something went wrong')
          })
    },
  }
};
</script>
